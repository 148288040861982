import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

export interface ICompanyModel {
  name: string;
  companytypeid: number;
  clientid: string;
  tenantid: number;
}

const COMPANY_KEY = '__app_company_';

@Injectable({ providedIn: 'root' })
export class TenantAuthService {
  private _currentTenant: ICompanyModel;
  get currentTenant(): ICompanyModel {
    return this._currentTenant;
  }

  get tenantId() {
    return this._currentTenant?.clientid;
  }

  constructor(private _storage: StorageService) {}

  async getTenant(): Promise<ICompanyModel> {
    if (this._currentTenant) {
      return this._currentTenant;
    }

    this._currentTenant = await this._storage.get(COMPANY_KEY);
    return this._currentTenant;
  }

  saveTenant(comapny: ICompanyModel): Promise<void> {
    this._currentTenant = comapny;
    return this._storage.set(COMPANY_KEY, comapny);
  }

  removeTenant(): Promise<void> {
    this._currentTenant = null;
    return this._storage.remove(COMPANY_KEY);
  }
}
