import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { OnlineOrderContract } from '../models/online-order';
import { ICompanyModel } from './tenant-auth.service';
import {
  IEmployeeSessionContract,
  IEmployeeSessionUpdate,
} from '../private/terminal/services/employee.service';
import { IPrinterItem } from '../private/terminal/repositories/printers.repository';

@Injectable({
  providedIn: 'root',
})
export class ApiV1Service {
  private get baseUrl() {
    return this.settings.api_endpoint;
  }

  private get posApiUrl() {
    return this.settings.pos_api_endpoint;
  }

  private _httpParams = {
    params: new HttpParams().set('responseFormater', 'camelCase'),
  };

  constructor(private http: HttpClient, private settings: SettingsService) {}

  getTenants(): Observable<ICompanyModel[]> {
    return this.http.get<ICompanyModel[]>(
      `${this.baseUrl}/app-client/user-clients`
    );
  }

  getUserSession(): Observable<string> {
    return this.http.get<string>(
      `${this.baseUrl}/app-client/company-user-session`
    );
  }

  selectTenants(tenantId: string): Observable<string> {
    return this.http.post<string>(
      `${this.baseUrl}/app-client/select-client/${tenantId}`,
      {}
    );
  }

  getCompanyFeatures(storeId: number): Observable<any> {
    return this.http.get<boolean>(`${this.posApiUrl}/Billing/features`, {
      params: new HttpParams()
        .set('responseFormater', 'none')
        .set('storeId', storeId),
    });
  }

  sendEmail(sendDate: {
    email: string;
    text?: string;
    html?: string;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}/pos/terminal/send-email`,
      sendDate
    );
  }

  getStoreSettings(companyId: string) {
    return this.http.get<any>(
      `${this.settings.store_api}/Company/${companyId}`
    );
  }

  approveOnlineOrder(companyId: string, orderId: string) {
    return this.http.put<any>(`${this.settings.store_api}/Order/approve`, {
      companyId: companyId,
      orderId: orderId,
    });
  }

  getProduct(productId: number) {
    return this.http.get<boolean>(
      `${this.baseUrl}/pos/terminal/product/${productId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
      }
    );
  }

  invoiceAddTaxRegister(fiscalNumber: string) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddTaxRegister`, {
      fiscalNumber: fiscalNumber,
    });
  }

  invoiceAddStore(storeId: number) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddStore`, {
      storeId: storeId,
    });
  }

  invoiceAddExpirenza() {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddExpirenza`, {});
  }

  invoiceAddTablesMap(storeId: number) {
    return this.http.patch(`${this.posApiUrl}/Billing/invoiceAddTablesMap`, {
      storeId: storeId,
    });
  }

  checkData() {
    return this.http.get<any>(`${this.posApiUrl}Data/Check`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getPromos() {
    return this.http.get<any[]>(`${this.posApiUrl}Data/Promos`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getCustomers() {
    return this.http.get<any[]>(`${this.posApiUrl}Data/Customers`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getHalls(storeId: number) {
    return this.http.get<any[]>(`${this.posApiUrl}Data/Halls/${storeId}`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getTerminal() {
    return this.http.get<any>(`${this.posApiUrl}Data/Terminal`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  linkTerminal(storeId: number) {
    return this.http.patch(`${this.posApiUrl}Data/Terminal/Store/${storeId}`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  unlinkTerminal() {
    return this.http.delete(`${this.posApiUrl}Data/Terminal/Store`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getStores() {
    return this.http.get<any[]>(`${this.posApiUrl}Data/Stores`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  // obsolete
  saveEmployeeSessionUpdate(
    emplyeeId: number,
    sessionId: string,
    action: 'startSession' | 'endSession' | 'openSession',
    date: Date
  ) {
    return this.http.put<void>(`${this.posApiUrl}/Employee/session`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  saveEmployeeSession(model: IEmployeeSessionUpdate) {
    return this.http.put<void>(`${this.posApiUrl}/Employee/session`, model, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getEmployeeSession(sessionId: string) {
    return this.http.get<any>(
      `${this.posApiUrl}/Employee/session/${sessionId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
      }
    );
  }

  // obsolete
  syncEmpoloyeeSessions(sessions: any[]) {
    return this.http.put(
      `${this.baseUrl}/pos/terminal/sync-employee-sessions`,
      sessions
    );
  }

  getEmployeeLastSession(employeeId: string) {
    return this.http.get<IEmployeeSessionContract>(
      `${this.posApiUrl}/Employee/${employeeId}/Session`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
      }
    );
  }

  syncCashboxSessions(sessions: any[]) {
    return this.http.put(
      `${this.baseUrl}/pos/terminal/sync-cashbox-sessions`,
      sessions
    );
  }

  getEmployeeSessionReport(sessionUuid: string) {
    return this.http.get(
      `${this.baseUrl}/pos/terminal/employeeReport/${sessionUuid}`
    );
  }

  getTerminalSettings() {
    return this.http.get<any>(`${this.baseUrl}/pos/terminal/settings`);
  }

  getOnlineOrders = (
    companyId: string,
    storeId: number
  ): Observable<OnlineOrderContract[]> =>
    this.http.get<any[]>(
      `${this.settings.store_api}/Order/${companyId}/${storeId}`,
      {
        params: new HttpParams().set('responseFormater', 'none'),
        headers: new HttpHeaders().set('companyId', companyId),
      }
    );

  getCompanyInfo() {
    return this.http.get<any>(`${this.posApiUrl}/Tenants/Current`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getPrinters() {
    return this.http.get<IPrinterItem[]>(`${this.posApiUrl}/Printers`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  updatePrinters(printers: IPrinterItem[]) {
    return this.http.put<any>(`${this.posApiUrl}/Printers`, printers, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  deletePrinter(printerId: string) {
    return this.http.delete(`${this.posApiUrl}/Printers/${printerId}`, {
      params: new HttpParams().set('responseFormater', 'none'),
    });
  }

  getDeliveryMethods = (): Observable<Object[]> =>
    this.http.get<Object[]>(
      `${this.baseUrl}/delivery/delivery-methods`,
      this._httpParams
    );

  getAddressHistory = (customerId: number): Observable<Object[]> =>
    this.http.get<Object[]>(
      `${this.baseUrl}/delivery/address-history/${customerId}`,
      this._httpParams
    );

  getDeliveryByOrder = (orderUuid: string): Observable<any> =>
    this.http.get<any>(
      `${this.baseUrl}/delivery/order/${orderUuid}`,
      this._httpParams
    );

  getDelivery = (deliveryUuid: string): Observable<any> =>
    this.http.get<any>(
      `${this.baseUrl}/delivery/${deliveryUuid}`,
      this._httpParams
    );

  saveDelivery = (model: any): Observable<void> =>
    this.http.post<void>(`${this.baseUrl}/delivery`, model, this._httpParams);

  updateDelivery = (deliveryId: number, model: any): Observable<void> =>
    this.http.patch<void>(
      `${this.baseUrl}/delivery/${deliveryId}`,
      model,
      this._httpParams
    );
}
