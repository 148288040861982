import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './_interceptors/token.interceptor';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';
import { environment } from '../environments/environment';
import { MaterialModule } from './material.module';
import { GlobalErrorHandler } from './services/global-error-handler';
import { PrinterService } from './shared/printer/printer.service';
import { TerminalService } from './private/terminal/services/terminal.service';
import { SocketService } from 'src/app/services/socket.service';
import { SharedService } from './services/shared.service';
import { TerminalSessionComponent } from './private/terminal/terminal-session.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyDeviceService } from './services/company-device.service';
import { AuthService } from './services/auth.service';
import { TerminalDataService } from './private/terminal/services/terminal-data.service';
import { UUID } from 'angular2-uuid';
import { HTTP } from '@ionic-native/http/ngx';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import localeEu from '@angular/common/locales/eu';
registerLocaleData(localeRu);
registerLocaleData(localeUk);
registerLocaleData(localeEu);

class VirtualDevice {
  private _deviceUuid: string;

  constructor() {}

  get uuid() {
    return this.getDeviceUuid();
  }

  get isVirtual() {
    return true;
  }

  get platform() {
    return 'web_browser';
  }

  get model() {
    return 'browser device';
  }

  private getDeviceUuid(): string {
    if (this._deviceUuid) {
      return this._deviceUuid;
    }

    let _deviceUuid = localStorage.getItem('__VIRTUAL_DEVICE_ID');
    if (_deviceUuid) {
      this._deviceUuid = _deviceUuid;
    } else {
      _deviceUuid = UUID.UUID();
      this._deviceUuid = _deviceUuid;
      localStorage.setItem('__VIRTUAL_DEVICE_ID', _deviceUuid);
    }

    return _deviceUuid;
  }
}

class PhysicalDevice {
  private _device = new Device();
  private _deviceUuid: string;

  constructor() {}

  get uuid() {
    return this.getDeviceUuid();
  }

  get isVirtual() {
    return this._device.isVirtual;
  }

  get platform() {
    return this._device.platform;
  }

  get model() {
    return this._device.model;
  }

  private getDeviceUuid(): string {
    if (this._device.uuid) {
      return this._device.uuid;
    }

    if (this._deviceUuid) {
      return this._deviceUuid;
    }

    let _deviceUuid = localStorage.getItem('__VIRTUAL_DEVICE_ID');
    if (_deviceUuid) {
      this._deviceUuid = _deviceUuid;
    } else {
      _deviceUuid = UUID.UUID();
      this._deviceUuid = _deviceUuid;
      localStorage.setItem('__VIRTUAL_DEVICE_ID', _deviceUuid);
    }

    return _deviceUuid;
  }
}

const deviceFactory = () => {
  if (!window['cordova']) {
    return new VirtualDevice();
  } else {
    return new PhysicalDevice();
  }
};

import { File } from '@ionic-native/file/ngx';
import { SignalRHubConnector } from './services/signal-r-hub.connector';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { TerminalClientService } from './private/terminal/services/terminal-client.service';
import { LanguagesService } from './services/languages.service';
import { TerminalAuthService } from './services/terminal-auth.service';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { CalendarModule } from 'ion2-calendar';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@NgModule({
  declarations: [AppComponent, TerminalSessionComponent],
  entryComponents: [],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    CalendarModule,

    IonicModule.forRoot({ mode: 'ios', animated: false }),
    IonicStorageModule.forRoot({
      name: '__nt_soft_pos_terminal',
      driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
    }),
  ],
  providers: [
    AndroidPermissions,
    BluetoothSerial,
    BluetoothLE,
    SplashScreen,
    StatusBar,
    Device,
    Network,
    TerminalService,
    PrinterService,
    SocketService,
    SharedService,
    CompanyDeviceService,
    AuthService,
    TerminalDataService,
    File,
    SignalRHubConnector,
    TerminalClientService,
    TerminalAuthService,
    Vibration,
    { provide: 'ENVIRONMENT', useValue: environment },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: Device, useFactory: deviceFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: LOCALE_ID,
      deps: [LanguagesService],
      useFactory: (languagesService: LanguagesService) =>
        languagesService.getCurrentLang(),
    },
    {
      provide: 'APP_LANGUAGE',
      deps: [LanguagesService],
      useFactory: (ls: LanguagesService) => {
        return ls.getCurrentLang();
      },
    },
    HTTP,
    AppVersion,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
