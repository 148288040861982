import { EventEmitter, Injectable } from '@angular/core';
import { GenericRepository } from '../repositories/generic.repository';
import { CompanyStorage } from 'src/app/_core/company-storage';
import DateUtils from 'src/app/utility/date-utils';

export class CustomerItem {
  id: number;
  birthday: string;
  firstName: string;
  genderId: string;
  lastName: string;
  patronymic: string;
  phone: string;
  isLastRequested?: boolean = false;
  bonusesAccount?: number = 0;
  percentDiscount?: number = 0;
  loyaltyDiscount?: number = 0;
  loyaltyTypeId?: number = 0;
  loyaltyName?: string;
  loyaltyPrograms: any[];
  loyaltyProgramId?: number;
  customeCode?: number;

  get fullName() {
    return `${this.firstName} ${this.firstName}`;
  }

  constructor(customer: CustomerItem) {
    if (!customer) {
      return;
    }

    this.id = customer.id;
    this.firstName = customer.firstName;
    this.lastName = customer.lastName;
    this.genderId = customer.genderId;
    this.patronymic = customer.patronymic;
    this.phone = customer.phone;
    this.isLastRequested = customer.isLastRequested;
    this.bonusesAccount = customer.bonusesAccount;
    this.percentDiscount = customer.percentDiscount;
    this.loyaltyDiscount = customer.loyaltyDiscount;
    this.loyaltyTypeId = customer.loyaltyTypeId;
    this.loyaltyName = customer.loyaltyName;
    this.loyaltyPrograms = customer.loyaltyPrograms;
    this.loyaltyProgramId = customer.loyaltyProgramId;
    this.customeCode = customer.customeCode;
    this.birthday = customer.birthday;
  }
}

const TABLE_KEY = '__customers';
const CUSTOMERS_TABLE_KEY = '__customers';

@Injectable({ providedIn: 'root' })
export class CustomersRepository {
  onCustomersUpdate = new EventEmitter<CustomerItem[]>();

  constructor(
    private _repository: GenericRepository,
    private _companyStorage: CompanyStorage
  ) {
    // this._syncLegacyOrders();
  }

  // legacy support
  // private async _syncLegacyOrders() {
  //   debugger
  //   const customers = await this._getCustomersLegacy();
  //   try {
  //     if (customers && customers.length) {
  //       for (let c of customers) {
  //         await this.saveCustomer(c);
  //       }
  //     }
  //   } catch {}

  //   await this.clearLegacyStorage();
  // }
  // legacy support

  async saveCustomer(customer: CustomerItem): Promise<void> {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    await repo.addOrupdateItem(customer, 'id', 'start');
    const customers = await this.getCustomers();
    this.onCustomersUpdate.emit(customers);
  }

  async getCustomers(): Promise<CustomerItem[]> {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    return repo.getItems();
  }

  private _getCustomersLegacy(): Promise<CustomerItem[]> {
    return this._repository.getItems<CustomerItem>(TABLE_KEY);
  }

  private clearLegacyStorage() {
    return this._repository.deleteTable(TABLE_KEY);
  }

  async loadCustomers(customers: CustomerItem[]) {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    repo.loadItems(customers);
  }

  async clearStorage() {
    const repo = await this._companyStorage.repository<CustomerItem>(
      CUSTOMERS_TABLE_KEY
    );

    repo.deleteTable();
  }
}
