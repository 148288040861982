import { Component, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { format } from 'date-fns';

@Component({
  selector: 'app-touch-datepicker',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <span *ngIf="isRange"> Оберіть період </span>
          <span *ngIf="!isRange"> Оберіть дату </span>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button color="dark" (click)="dimissDatepicker()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="h-100">
        <ion-row class="h-100">
          <ion-col class="ion-padding h-100">
            <table class="h-100">
              <tr>
                <td>
                  <ion-item [hidden]="!isRange" class="mb-3" lines="none">
                    <ion-label><h2>Початок періоду</h2></ion-label>
                    <ion-label slot="end"
                      ><h2>
                        <strong>{{ fromDate | date : 'dd.MM.yyyy' }}</strong>
                      </h2></ion-label
                    >
                  </ion-item>
                </td>
              </tr>
              <tr>
                <td>
                  <ion-datetime
                    class="tp-datepicker"
                    #dateTime
                    [presentation]="presentation"
                    size="cover"
                    (ionChange)="change($event)"
                    locale="uk-UA"
                    [firstDayOfWeek]="1"
                    #fromDateTime
                    mode="md"
                    ><span slot="time-label">Час</span></ion-datetime
                  >
                </td>
              </tr>
            </table>
          </ion-col>
          <ion-col [hidden]="!isRange" class="ion-padding h-100">
            <table class="h-100">
              <tr>
                <td>
                  <ion-item class="mb-3" lines="none">
                    <ion-label><h2>Кінець періоду</h2></ion-label>
                    <ion-label slot="end"
                      ><h2>
                        <strong>{{ toDate | date : 'dd.MM.yyyy' }}</strong>
                      </h2></ion-label
                    >
                  </ion-item>
                </td>
              </tr>
              <tr>
                <td>
                  <ion-datetime
                    class="tp-datepicker"
                    [presentation]="presentation"
                    size="cover"
                    (ionChange)="changeToDate($event)"
                    locale="uk-UA"
                    [firstDayOfWeek]="1"
                    #toDateTime
                    mode="md"
                  >
                    <span slot="time-label">Час</span></ion-datetime
                  >
                </td>
              </tr>
            </table>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer
      class="ion-text-end ion-padding-start ion-padding-end ion-padding-bottom"
    >
      <ion-button
        class="ion-margin-end"
        color="dark"
        fill="outline"
        size="large"
        (click)="dimissDatepicker()"
        >Закрити</ion-button
      >

      <ion-button
        color="primary"
        fill="outline"
        size="large"
        [disabled]="!isChanged"
        (click)="confirm()"
        >Вибрати</ion-button
      >
    </ion-footer>
  `,
  styleUrls: ['./touch-datepicker.component.scss'],
})
export class TouchDatepickerComponent implements OnInit {
  @ViewChild('fromDateTime') fromDatepicker: IonDatetime;
  @ViewChild('toDateTime') toDatepicker: IonDatetime;

  isChanged = false;
  isRange = false;
  type: 'range' | 'date' = 'date';
  toDate: Date;
  fromDate: Date;
  presentation:
    | 'date'
    | 'date-time'
    | 'month'
    | 'month-year'
    | 'time'
    | 'time-date'
    | 'year' = 'date';
  hasTime = false;
  constructor(private _modalCtrl: ModalController) {}

  ngAfterViewInit() {
    if (this.fromDate) {
      this.fromDatepicker.reset(this._toLocalDateString(this.fromDate));
    }

    if (this.toDate) {
      this.toDatepicker.reset(this._toLocalDateString(this.toDate));
    }
  }

  ngOnInit() {
    this.hasTime = this.presentation == 'date-time';
    this.isRange = this.type == 'range';
  }

  change(event: any) {
    if (!event.detail) {
      return;
    }

    this.isChanged = true;
    this.fromDate = new Date(event.detail.value);
  }

  changeToDate(event: any) {
    if (!event.detail) {
      return;
    }

    this.isChanged = true;
    this.toDate = new Date(event.detail.value);
  }

  confirm() {
    if (this.isRange) {
      this._modalCtrl.dismiss(
        { fromDate: this.fromDate, toDate: this.toDate },
        'confirm'
      );
    } else {
      this._modalCtrl.dismiss(this.fromDate, 'confirm');
    }
  }

  dimissDatepicker() {
    this._modalCtrl.dismiss(null, 'dismiss');
  }

  private _toLocalDateString(date: Date) {
    return (
      format(date, 'yyyy-MM-dd') + 'T' + format(date, 'HH:mm:ss') + '+00:00'
    );
  }
}
