import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, of, forkJoin, from } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

import { AuthService } from '../services/auth.service';
import EntityUtils from '../utility/entity-utils';
import { CompanyService } from '../private/tenants/company.service';
import { CompanyDeviceService } from '../services/company-device.service';
import { HTTP } from '@ionic-native/http/ngx';
import { TerminalAuthService } from '../services/terminal-auth.service';
import { SharedService } from '../services/shared.service';
import { NetworkService } from '../services/network.service';
import { TenantAuthService } from '../services/tenant-auth.service';
import { Device } from '@ionic-native/device/ngx';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshing = false;

  constructor(
    private authService: AuthService,
    private _companyService: CompanyService,
    private _companyDeviceService: CompanyDeviceService,
    private loadingController: LoadingController,
    private _nativeHttp: HTTP,
    private _platform: Platform,
    private terminalAuthService: TerminalAuthService,
    private _shared: SharedService,
    private _network: NetworkService,
    private _tenantAuth: TenantAuthService,
    private _device: Device
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._addHeaders(req).pipe(
      switchMap((request: HttpRequest<any>) => {
        const skipHandlers = request.params.get('skip');
        const responseFormater = request.params.get('responseFormater');
        if (skipHandlers) {
          return this._getHttpResponse(request, next, responseFormater);
        } else
          return this._getHttpResponse(request, next, responseFormater).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status <= 0) {
                this._network.goOffline();
              }

              if (error.status === 401) {
                return this.handle401Error(request, next, responseFormater);
              }

              if (error.status === 403) {
                this._shared.showErrorToast('Відсутний доступ', 5);
              }

              return throwError(error);
            }),
            map((resp) => {
              this._network.goOnline();
              return resp;
            })
          );
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    formatter?: string
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return this._companyService.getAndStoreCompanyToken().pipe(
            mergeMap(() => {
              return this._addHeaders(request).pipe(
                switchMap((req) => this._getHttpResponse(req, next, formatter))
              );
            })
          );
        })
        // catchError((error: HttpErrorResponse) => {
        //   this.isRefreshing = false;
        //   return this._companyService
        //     .clearCompanyToken()
        //     .pipe(switchMap(() => throwError(error)));
        // })
      );
    } else {
      return this._addHeaders(request).pipe(
        switchMap((req) => this._getHttpResponse(req, next, formatter))
      );
    }
  }

  private _getHttpResponse(
    request: HttpRequest<any>,
    next: HttpHandler,
    formatter?: string
  ) {
    if (
      this._platform.is('cordova') &&
      !request.url.startsWith('http://localhost') &&
      !request.url.startsWith('../../assets')
    ) {
      return from(this._handleNativeRequest(request)).pipe(
        map((event: HttpResponse<any>) => {
          let responseBody: any;
          if (typeof event.body === 'number') {
            responseBody = event.body;
          } else if (formatter == 'camelCase') {
            responseBody = EntityUtils.toCamel(event.body);
          } else if (formatter == 'none') {
            responseBody = event.body;
          } else {
            responseBody = EntityUtils.toLowerKeys(event.body);
          }

          event = event.clone({ body: responseBody });
          // this.hideLoader();
          return event;
        })
      );
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let responseBody: any;
          if (typeof event.body === 'number') {
            responseBody = event.body;
          } else if (formatter == 'camelCase') {
            responseBody = EntityUtils.toCamel(event.body);
          } else if (formatter == 'none') {
            responseBody = event.body;
          } else {
            responseBody = EntityUtils.toLowerKeys(event.body);
          }

          event = event.clone({ body: responseBody });
        }
        // this.hideLoader();
        return event;
      })
    );
  }

  private _addHeaders(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this._getAuthHeaders().pipe(
      mergeMap((headers) => {
        if (headers) {
          request = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + headers.access_token,
              UserSessionId: headers.userSessionId || '',
              DeviceId: headers.deviceId || '',
              ConnectionToken: headers.connectionToken || '',
              DeviceToken: headers.deviceToken || '',
              EmployeeId: headers.employeeId || '',
              CompanyId: this._tenantAuth.tenantId || '',
              deviceImei: this._device.uuid,
            },
          });
        }

        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            setHeaders: {
              'content-type': 'application/json',
            },
          });
        }

        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });

        return of(request);
      })
    );
  }

  hideLoader() {
    this.loadingController.dismiss();
  }

  private _getAuthHeaders(): Observable<any> {
    return forkJoin([
      this.authService.accessToken(),
      this._companyService.getCompanySessionId(),
      from(this._companyDeviceService.installedDeviceId()),
      this._companyService.getCompanyToken(),
      this._companyService.deviceToken,
    ]).pipe(
      map((result: any[]) => {
        return {
          access_token: result[0],
          userSessionId: result[1],
          deviceId: result[2],
          connectionToken: result[3],
          deviceToken: result[4],
          employeeId:
            this.terminalAuthService.getTerminalSession()?.employee_id,
        };
      })
    );
  }

  private async _handleNativeRequest(
    request: HttpRequest<any>
  ): Promise<HttpResponse<any>> {
    const headerKeys = request.headers.keys();
    const headers = {};

    headerKeys.forEach((key) => {
      headers[key] = request.headers.get(key);
    });

    try {
      await this._platform.ready();

      let _data = request.body;
      if (!_data) {
        _data = {};
      }

      let _serializer: any = 'json';
      if (typeof _data == 'string') {
        _serializer = 'utf8';
      }
      const method = <any>request.method.toLowerCase();
      const nativeHttpResponse = await this._nativeHttp.sendRequest(
        request.urlWithParams,
        {
          method: method,
          data: _data,
          headers: headers,
          serializer: _serializer,
        }
      );

      let body = {};
      if (nativeHttpResponse.data) {
        try {
          body = JSON.parse(nativeHttpResponse.data);
        } catch (error) {
          body = { response: nativeHttpResponse.data };
        }
      }

      const response = new HttpResponse({
        body: body,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });

      // console.log('— Response success');
      // console.log(response);

      return Promise.resolve(response);
    } catch (error) {
      console.log('— Request url');
      console.log(request.url);
      console.log('— Request body');
      console.log(request.body);

      if (!error.status) {
        return Promise.reject(error);
      }

      console.log('— Response error');
      console.log(JSON.stringify(error));

      let errorObject = {};

      const _error = error.error || error;
      if (_error) {
        if (typeof _error == 'string' && _error.startsWith('No network')) {
          this._network.goOffline();
        } else {
          try {
            errorObject = JSON.parse(_error);
          } catch {
            errorObject = error;
          }
        }
      }

      const response = new HttpErrorResponse({
        error: errorObject,
        status: error.status,
        headers: error.headers,
        url: error.url,
      });

      return Promise.reject(response);
    }
  }
}
