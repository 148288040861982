import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, throwError } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { LanguagesService } from 'src/app/services/languages.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiV1Service } from 'src/app/services/api-v1.service';
import { Platform } from '@ionic/angular';

const POS_TERMINAL_SETTINGS = 'POS_TERMINAL_SETTINGS';

export interface ITerminalData {
  customers: any[];
  discounts: any[];
  employees: any[];
  locales: any;
  store: any;
  company: any;
  suppliers: any[];
  supply_products: any[];
  terminal: any;
  cashbox_shift: any;
  receipt_wishes: string[];
  token?: string;
  printer_settings: any;
  partners: any[];
  loyaltyprograms: any[];
  payment_methods: any[];
  halls: any[];
}

export class TerminalData {
  customers: any[];
  discounts: any[];
  employees: any[];
  locales: any;
  store: any;
  suppliers: any[];
  supply_products: any[];
  terminal: any;
  cashbox_shift: any;
  receipt_wishes: string[];
  printer_settings: any;

  constructor(conf: ITerminalData = null) {
    this.customers = conf?.customers || [];
    this.discounts = conf?.discounts || [];
    this.employees = conf?.employees || [];
    this.locales = conf?.locales || {};
    this.store = conf?.store || {};
    this.suppliers = conf?.suppliers || [];
    this.supply_products = conf?.supply_products || [];
    this.terminal = conf?.terminal || [];
    this.cashbox_shift = conf?.cashbox_shift || {};
    this.receipt_wishes = conf?.receipt_wishes || [];
  }
}

@Injectable({ providedIn: 'root' })
export class TerminalDataService {
  private _settingsSub = new BehaviorSubject<ITerminalData>(null);
  private _settings: Observable<ITerminalData>;

  constructor(
    private _storage: StorageService,
    private languagesService: LanguagesService,
    private _plt: Platform,
    private api: ApiV1Service
  ) {
    this._loadStoredData();
    this._settings.subscribe();
  }

  private _onTerminalData = new BehaviorSubject<ITerminalData>(null);

  getTerminalData(): Observable<ITerminalData> {
    return new Observable((observer) => {
      from(this._storage.get(POS_TERMINAL_SETTINGS)).subscribe((res) => {
        if (res) {
          observer.next(res);
        } else {
          this._onTerminalData.subscribe((event) => observer.next(event));
        }
      });
    });
  }

  getStoredSettings(): Promise<ITerminalData> {
    return this._storage.get(POS_TERMINAL_SETTINGS);
  }

  terminalSettings(): ITerminalData {
    return this._settingsSub.value;
  }

  async getTerminalSettingsNew(): Promise<ITerminalData> {
    const settings = await this.api.getTerminalSettings().toPromise();
    this._settingsSub.next(settings);
    this.languagesService.setLanguage(settings.terminal.lang);
    await this._saveSettings(settings);
    return settings;
  }

  private _loadStoredData() {
    const platformObs = from(this._plt.ready());
    this._settings = platformObs.pipe(
      switchMap(() => {
        return from(this._storage.get(POS_TERMINAL_SETTINGS));
      }),
      map((settings: any) => {
        if (settings) {
          this.languagesService.setLanguage(settings.terminal.lang);
          this._settingsSub.next(settings);
          return settings;
        } else {
          return null;
        }
      })
    );
  }

  clearSettings() {
    this._storage.remove(POS_TERMINAL_SETTINGS);
    this._settingsSub.next(null);
  }

  private async _saveSettings(settings: ITerminalData) {
    await this._storage.set(POS_TERMINAL_SETTINGS, settings);
    this._settingsSub.next(settings);
  }
}
