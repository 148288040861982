import { Injectable } from '@angular/core';
import {
  IPrintObject,
  PrinterService,
} from 'src/app/shared/printer/printer.service';
import { IReceipt } from './terminal.models';
import { ReceiptFormatterService } from './receipt-formatter.service';
import {
  ITerminalData,
  TerminalDataService,
} from './services/terminal-data.service';
import { OrderDTO } from './repositories/orders.repository';
import { CheckComponent } from 'src/app/shared/printer/components/check.component';
import { ITaxOrder } from 'src/app/common/tax/models/tax-order.model';
import { IPrinterItem } from './repositories/printers.repository';
import DateUtils from 'src/app/utility/date-utils';
import { ModalController } from '@ionic/angular';
import MoneyUtils from 'src/app/utility/money-utils';
import { APP_CONFIG, SettingsService } from 'src/app/services/settings.service';
import OrderUtils from 'src/app/utility/order-utils';
import { xml2json } from 'src/app/utility/xml-utils';
import {
  CHECKHEAD,
  ShiftTotalsOrderType,
} from 'src/app/common/tax/models/tax.model';
import { CurrencyService } from 'src/app/services/currency.service';

class PrintProduct {
  amount: number;
  code: number;
  letters: string;
  name: string;
  cost: number;
  quantity: number;
  price: number;
  uktZedCode: string;
  unitCode: string;
  unitName: string;
  exciseLabels?: string[];
}

class ZREP {
  ZREPHEAD: ZREPHEAD;
  ZREPREALIZ: ZREPREALIZ;
  ZREPRETURN: ZREPRETURN;
  ZREPBODY: ZREPBODY;
}

class ZREPHEAD {
  'UID': string;
  'TIN': number;
  IPN: number;
  'ORGNM': string;
  'POINTNM': string;
  'POINTADDR': string;
  'ORDERDATE': string;
  'ORDERTIME': string;
  'ORDERNUM': number;
  'CASHDESKNUM': number;
  'CASHREGISTERNUM': number;
  'CASHIER': string;
  'VER': number;
  'ORDERTAXNUM': string;
  TESTING: 'true';
}

class ZREPREALIZ {
  'SUM': number;
  'ORDERSCNT': number;
  'PAYFORMS': any;
  TAXES: any;
}
class ZREPRETURN {
  'SUM': number;
  'ORDERSCNT': number;
  'PAYFORMS': any;
  TAXES: any;
}

class ZREPBODY {
  SERVICEINPUT: number;
  SERVICEOUTPUT: number;
}

const SOFT_PROVIDER_NAME = 'TurboPOS';

@Injectable({ providedIn: 'root' })
export class ReceiptService {
  private printers: IPrinterItem[];
  private printObject: any = {};

  private get terminalData(): ITerminalData {
    return this._terminalData.terminalSettings();
  }
  constructor(
    private printerService: PrinterService,
    private _terminalData: TerminalDataService,
    private modalController: ModalController,
    private settings: SettingsService,
    private currencyService: CurrencyService
  ) {}

  async printTaxOrder(taxOrder: ITaxOrder): Promise<void> {
    const terminalSettings = this._terminalData.terminalSettings();
    const logo = terminalSettings.printer_settings.logo;
    const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
    const taxLink = OrderUtils.getTaxLink(
      taxCheckUrl,
      taxOrder.ORDERTAXNUM,
      taxOrder.cashRegisterNum,
      taxOrder.CHECKTOTAL,
      new Date().toISOString()
    );

    if (!this.printerService.isPrinterConfigured) {
      this.displayReceipt(
        taxOrder.toString(32, this.currencyService.name),
        taxLink
      );
    }

    if (this.printerService.hasPrinters) {
      await this._printTaxOrder(taxOrder, taxLink);
    }
  }

  async printXmlDocument(xmlCheck: string) {
    console.log(xmlCheck);
    const doc = xml2json(xmlCheck);
    if (!doc) {
      return;
    }

    if (!this.printerService.isPrinterConfigured) {
      // this.displayReceipt(taxOrder.toString(32), taxLink);
    }

    if (this.printerService.hasPrinters) {
      return this.printTaxCheck(doc.CHECK);
    }
  }

  async printTaxCheck(taxCheck: {
    CHECKBODY: any;
    CHECKHEAD: CHECKHEAD;
    CHECKTOTAL: any;
    CHECKPAY: any;
    CHECKTAX: any;
  }): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const _selectedPrinter = this.printerService.printers.find(
      (p) => p.printCheck
    );

    if (!_selectedPrinter) {
      return;
    }

    const orderDate = this._getTaxDocumentDate(
      taxCheck.CHECKHEAD.ORDERDATE,
      taxCheck.CHECKHEAD.ORDERTIME
    );

    const _taxLink = this._getTaxLink(
      taxCheck.CHECKHEAD.ORDERTAXNUM,
      taxCheck.CHECKHEAD.CASHREGISTERNUM,
      orderDate.toISOString(),
      taxCheck.CHECKTOTAL.SUM
    );

    _receiptFormatterService.config.width =
      this._getCharectersLength(_selectedPrinter);
    let _docText = '';
    if (_selectedPrinter.type == 'web') {
      _docText = await this._checkOutputHtmlFromXml(taxCheck);
    } else {
      _docText = this._checkOutputTextFromXml(
        _receiptFormatterService,
        taxCheck
      );
    }

    let printObject = this.printerService.getPrintObject(
      _selectedPrinter,
      _docText,
      true
    );

    printObject.bottomQRCodeData = _taxLink;
    if (_taxLink) {
      printObject.bottomText = SOFT_PROVIDER_NAME;
    }

    return this.printerService.printScope([printObject]);
  }

  private _getTaxLink(
    fiscalNumber: string,
    taxRegisterFiscalNumber: string,
    opened: string,
    totalAmount?: number
  ): string {
    const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
    let taxLink = OrderUtils.getTaxLink(
      taxCheckUrl,
      fiscalNumber,
      taxRegisterFiscalNumber,
      totalAmount,
      opened
    );

    return taxLink;
  }

  displayReceipt(receipt: string, qrCodeData: string, logo?: string) {
    this.modalController
      .create({
        component: CheckComponent,
        componentProps: {
          printText: receipt,
          qrCodeData: qrCodeData,
          logoDataUrl: logo,
        },
        canDismiss: true,
        backdropDismiss: true,
        cssClass: 'check-preview',
      })
      .then((modal) => modal.present());
  }

  opnenReceipt(
    receipt: IReceipt | OrderDTO,
    qrCodePayment?: { msgBefore: string; msgAfter: string; url: string }
  ) {
    const _receiptFormatterService = new ReceiptFormatterService();
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
    }

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';
    _receiptFormatterService.config.width = 46;
    const terminalSettings = this._terminalData.terminalSettings();

    let taxLink;
    let printText;
    if (receipt.xmlCheck) {
      const doc = xml2json(receipt.xmlCheck) as any;
      if (!doc || !doc.CHECK) {
        return;
      }

      const taxCheck = doc.CHECK;
      const orderDate = this._getTaxDocumentDate(
        taxCheck.CHECKHEAD.ORDERDATE,
        taxCheck.CHECKHEAD.ORDERTIME
      );

      taxLink = this._getTaxLink(
        taxCheck.CHECKHEAD.ORDERTAXNUM,
        taxCheck.CHECKHEAD.CASHREGISTERNUM,
        orderDate.toISOString(),
        taxCheck.CHECKTOTAL.SUM
      );

      printText = this._checkOutputTextFromXml(
        _receiptFormatterService,
        taxCheck
      );
    } else {
      const order = new OrderDTO(receipt);
      const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
      taxLink = OrderUtils.getTaxLink(
        taxCheckUrl,
        order.fiscalNumber,
        order.taxRegisterFiscalNumber,
        order.totalAmount,
        order.opened
      );

      const showOrderNumber = terminalSettings.terminal.checkshowordernumber;
      let printLines = this._outputText(
        order,
        receipt_header,
        receipt_footer,
        showOrderNumber
      );
      printText = _receiptFormatterService.create(printLines);
    }

    const logo = terminalSettings.printer_settings.logo;
    this.modalController
      .create({
        component: CheckComponent,
        componentProps: {
          printText: printText,
          qrCodeData: taxLink,
          logoDataUrl: logo,
          qrCodePayment: qrCodePayment,
        },
        canDismiss: true,
        backdropDismiss: true,
        cssClass: 'check-preview',
      })
      .then((modal) => modal.present());
  }

  printDelivery(order: IReceipt): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    order.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width = this._getCharectersLength(printer);
    let printText: string;
    if (printer.type == 'web') {
      printText = this._getDeliveryHtml(order);
    } else {
      const lines = this._getDeliveryLines(order);
      printText = _receiptFormatterService.create(lines);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  printDelivery2(delivery: any): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    delivery.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width = this._getCharectersLength(printer);
    let printText: string;
    if (printer.type == 'web') {
      // printText = this._getDeliveryHtml(order);
    } else {
      const lines = this._getDeliveryLines(delivery);
      printText = _receiptFormatterService.create(lines);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  async printPreOrder(
    order: IReceipt,
    qrCodePayment: { msgBefore: string; msgAfter: string; url: string }
  ) {
    if (this.printerService.isPrinterConfigured) {
      this._printPreOrder(order, qrCodePayment);
    } else {
      this.opnenReceipt(order, qrCodePayment);
    }
  }

  async printWorkAreas(receipt: IReceipt): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    let printerObjArr: IPrintObject[] = [];
    this.printObject.order = new OrderDTO(receipt);

    this.printers.forEach((p) => {
      _receiptFormatterService.config.width = this._getCharectersLength(p);
      if (p.work_areas && p.work_areas.length > 0) {
        p.work_areas.forEach((wa) => {
          let printLines = [];
          if (wa) {
            printLines = this._outputWorkAreaLines(
              this.printObject.order,
              wa.id,
              wa.name
            );
          }

          const printText = _receiptFormatterService.create(printLines);
          if (printText) {
            let printObject = this.printerService.getPrintObject(p, printText);
            printerObjArr.push(printObject);
          }
        });
      }
    });

    const terminalSettings = this._terminalData.terminalSettings();
    printerObjArr.forEach((p) => {
      if (p.printer.printCheck && p.printer.printLogo && p.isClientReceipt) {
        p.imglogo = terminalSettings.printer_settings.logo;
      }
    });

    return this.printerService.printScope(printerObjArr).then(() => {
      delete this.printObject.order;
    });
  }

  async _printPreOrder(
    receipt: IReceipt | OrderDTO,
    qrCodePayment: { msgBefore: string; msgAfter: string; url: string },
    isCopy = false
  ): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    const ps = this.terminalData.printer_settings;

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
    }

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const order = new OrderDTO(receipt);
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width = this._getCharectersLength(printer);
    let printText: string;
    if (printer.type == 'web') {
      printText = this._getReceiptHtml(order, null, null, isCopy);
    } else {
      printText = this._outputPreOrderText(order, _receiptFormatterService);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    const terminalSettings = this._terminalData.terminalSettings();
    if (printObject.printer.printLogo) {
      printObject.imglogo = terminalSettings.printer_settings.logo;
    }

    if (qrCodePayment) {
      printObject.extraData = [
        { type: 'space' },
        { type: 'text', value: qrCodePayment.msgBefore },
        { type: 'qrCode', value: qrCodePayment.url },
        { type: 'text', value: qrCodePayment.msgAfter },
      ];
    }

    if (printer.printLogo) {
      printObject.imglogo = terminalSettings.printer_settings.logo;
    }
    return this.printerService.printScope([printObject]);
  }

  async printReceipt(order: IReceipt | OrderDTO, isCopy = false) {
    if (!this.printerService.isPrinterConfigured) {
      this.opnenReceipt(order);
    }

    if (this.printerService.hasPrinters) {
      this._printReceipt(order, isCopy);
    }
  }

  async printOrder(order: IReceipt | OrderDTO, isCopy = false): Promise<void> {
    if (!this.printerService.isPrinterConfigured) {
      this.opnenReceipt(order);
      return Promise.resolve();
    }

    if (this.printerService.hasPrinters) {
      return this._printOrder(order, isCopy);
    }
  }

  async _printTaxOrder(taxOrder: ITaxOrder, taxLink: string): Promise<void> {
    this.printers = this.printerService.printers;
    const selectedPrinter = this.printers.find((p) => p.printCheck);
    if (!selectedPrinter) {
      return;
    }

    let text = '';
    if (selectedPrinter.type == 'web') {
      text = taxOrder.toHtml();
    } else {
      const checkWidth = this._getCharectersLength(selectedPrinter);
      text = taxOrder.toString(checkWidth, this.currencyService.name);
    }

    let printObject = this.printerService.getPrintObject(
      selectedPrinter,
      text,
      true
    );
    printObject.bottomQRCodeData = taxLink;
    if (taxLink) {
      printObject.bottomText = SOFT_PROVIDER_NAME;
    }

    const terminalSettings = this._terminalData.terminalSettings();
    if (selectedPrinter.printLogo) {
      printObject.imglogo = terminalSettings.printer_settings.logo;
    }

    return this.printerService.printScope([printObject]);
  }

  async _printReceipt(
    receipt: IReceipt | OrderDTO,
    isCopy = false
  ): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    let address = null;
    if (ps && ps.print_address) {
      address = this.terminalData.store.address;
    }

    receipt.wish = this._getNext();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    let printerObjArr: IPrintObject[] = [];

    const order = new OrderDTO(receipt);
    this.printObject.order = order;

    const taxCheckUrl = this.settings.getValue(APP_CONFIG.TAX_CHECK_URL);
    let taxLink = '';
    if (order.fiscalNumber) {
      taxLink = OrderUtils.getTaxLink(
        taxCheckUrl,
        order.fiscalNumber,
        order.taxRegisterFiscalNumber,
        order.totalAmount,
        order.documentDate
      );
    }

    const terminalSettings = this._terminalData.terminalSettings();
    const showOrderNumber = terminalSettings.terminal.checkshowordernumber;

    this.printers.forEach((p) => {
      _receiptFormatterService.config.width = this._getCharectersLength(p);

      if (p.printCheck) {
        let text = '';
        if (p.type == 'web') {
          text = this._getReceiptHtml(
            this.printObject.order,
            receipt_header,
            receipt_footer,
            isCopy,
            showOrderNumber
          );
        } else {
          const printLines = this._outputText(
            this.printObject.order,
            receipt_header,
            receipt_footer,
            isCopy,
            showOrderNumber
          );
          text = _receiptFormatterService.create(printLines);
        }

        let printObject = this.printerService.getPrintObject(p, text, true);
        printObject.bottomQRCodeData = taxLink;
        if (taxLink) {
          printObject.bottomText = SOFT_PROVIDER_NAME;
        }
        printerObjArr.push(printObject);
      }

      if (isCopy) {
        return;
      }

      if (!p.printWorkAreasOnPayment) {
        return;
      }

      if (p.work_areas && p.work_areas.length > 0) {
        p.work_areas.forEach((wa) => {
          let printLines = [];
          if (wa == null) {
            printLines = this._outputText(
              this.printObject.order,
              receipt_header,
              receipt_footer,
              isCopy
            );
          } else {
            printLines = this._outputWorkAreaLines(
              this.printObject.order,
              wa.id,
              wa.name
            );
          }

          const printText = _receiptFormatterService.create(printLines);
          if (printText) {
            let printObject = this.printerService.getPrintObject(p, printText);
            printerObjArr.push(printObject);
          }
        });
      }
    });

    printerObjArr.forEach((p) => {
      if (p.printer.printCheck && p.printer.printLogo && p.isClientReceipt) {
        p.imglogo = terminalSettings.printer_settings.logo;
      }
    });

    return this.printerService.printScope(printerObjArr).then(() => {
      delete this.printObject.order;
    });
  }

  async _printOrder(
    receipt: IReceipt | OrderDTO,
    isCopy = false
  ): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    const ps = this.terminalData.printer_settings;
    receipt.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    if (ps) {
      receipt_header = ps.receipt_header;
      receipt_footer = ps.receipt_footer;
    }

    if (ps && ps.print_address) {
      receipt.pointAddress = this.terminalData.store.address;
    }

    receipt.wish = this._getNext();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    let printerObjArr: IPrintObject[] = [];

    const order = new OrderDTO(receipt);
    this.printObject.order = order;

    const terminalSettings = this._terminalData.terminalSettings();
    const showOrderNumber = terminalSettings.terminal.checkshowordernumber;

    this.printers.forEach((p) => {
      _receiptFormatterService.config.width = this._getCharectersLength(p);

      if (p.printCheck) {
        let text = '';
        if (p.type == 'web') {
          text = this._getOrderHtml(
            this.printObject.order,
            receipt_header,
            receipt_footer,
            isCopy,
            showOrderNumber
          );
        } else {
          const printLines = this._outputText(
            this.printObject.order,
            receipt_header,
            receipt_footer,
            isCopy,
            showOrderNumber
          );
          text = _receiptFormatterService.create(printLines);
        }

        let printObject = this.printerService.getPrintObject(p, text, true);
        printerObjArr.push(printObject);
      }

      if (isCopy) {
        return;
      }

      if (!p.printWorkAreasOnPayment) {
        return;
      }

      if (p.work_areas && p.work_areas.length > 0) {
        p.work_areas.forEach((wa) => {
          let printLines = [];
          if (wa == null) {
            printLines = this._outputText(
              this.printObject.order,
              receipt_header,
              receipt_footer,
              isCopy
            );
          } else {
            printLines = this._outputWorkAreaLines(
              this.printObject.order,
              wa.id,
              wa.name
            );
          }

          const printText = _receiptFormatterService.create(printLines);
          if (printText) {
            let printObject = this.printerService.getPrintObject(p, printText);
            printerObjArr.push(printObject);
          }
        });
      }
    });

    printerObjArr.forEach((p) => {
      if (p.printer.printCheck && p.printer.printLogo && p.isClientReceipt) {
        p.imglogo = terminalSettings.printer_settings.logo;
      }
    });

    return this.printerService.printScope(printerObjArr).then(() => {
      delete this.printObject.order;
    });
  }

  async printSalesReport(report: any): Promise<void> {
    const _receiptFormatterService = new ReceiptFormatterService();
    report.store_name = this.terminalData.store.name;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    this.printers = this.printerService.printers;
    const printer = this.printers.find((p) => p.printCheck);
    if (!printer) {
      throw new Error('Принтер не знайдено.');
    }

    _receiptFormatterService.config.width = this._getCharectersLength(printer);

    let printText: string;
    if (printer.type == 'web') {
      printText = this._getSalesReportHtml(report);
    } else {
      printText = this._getSalesReportString(report, _receiptFormatterService);
    }

    const printObject = this.printerService.getPrintObject(
      printer,
      printText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  private _getOrderHtml(
    receipt: OrderDTO,
    header: string,
    footer: boolean,
    isCopy = false,
    showOrderNumber = false
  ): string {
    return `${this._getOrderHeaderHtml(
      receipt,
      header,
      isCopy,
      showOrderNumber
    )}${this._productsHtml(receipt)}${this._getOrderSummary(
      receipt
    )}${this._getDelivery(receipt)}${this._getReceiptComment(
      receipt
    )} ${this._getFooter(footer, receipt)}${this._getWish(receipt)}`;
  }

  private _getReceiptHtml(
    receipt: OrderDTO,
    header: string,
    footer: boolean,
    isCopy = false,
    showOrderNumber = false
  ): string {
    return `${this._getHeaderHtml(
      receipt,
      header,
      isCopy,
      showOrderNumber
    )}${this._productsHtml(receipt)}${this._getReceiptSummary(
      receipt
    )}${this._getDelivery(receipt)}${this._getReceiptComment(
      receipt
    )} ${this._getFooter(footer, receipt)}${this._getWish(receipt)}`;
  }

  printReport(taxOrder: ITaxOrder): Promise<void> {
    if (!taxOrder) {
      alert('Немає даних для звіту');
      return;
    }

    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const _selectedPrinter = this.printerService.printers.find(
      (p) => p.printCheck
    );

    if (!_selectedPrinter) {
      return;
    }

    _receiptFormatterService.config.width =
      this._getCharectersLength(_selectedPrinter);
    let _zReportText = '';
    if (_selectedPrinter.type == 'web') {
      _zReportText = this._reportOutputHtmlFromObject(taxOrder);
    } else {
      _zReportText = this._reportOutputText(_receiptFormatterService, taxOrder);
    }

    let printObject = this.printerService.getPrintObject(
      _selectedPrinter,
      _zReportText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  getTaxReportText(taxOrder: ITaxOrder, header = true): string {
    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    _receiptFormatterService.config.width = this._getCharectersLength(null);
    const _zReportText = this._reportOutputText(
      _receiptFormatterService,
      taxOrder,
      header
    );

    return _zReportText;
  }
  private _getCharectersLength(printer: IPrinterItem) {
    if (!printer) {
      return 32;
    }

    if (printer.printWidthSymbols > 0) {
      return printer.printWidthSymbols;
    }

    if (printer.printWidth == '80' && printer.textSize == 'small') {
      return 52;
    }

    if (printer.printWidth == '80' && printer.textSize == 'normal') {
      return 42;
    }

    if (printer.printWidth == '58' && printer.textSize == 'small') {
      return 42;
    }

    if (printer.printWidth == '58') {
      return 32;
    }

    return +printer.printWidth;
  }

  printReportDocument(xmlDocument: { ZREP: ZREP }): Promise<void> {
    if (!xmlDocument) {
      alert('Немає даних для звіту');
      return;
    }

    const _receiptFormatterService = new ReceiptFormatterService();
    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';

    const _selectedPrinter = this.printerService.printers.find(
      (p) => p.printCheck
    );

    if (!_selectedPrinter) {
      return;
    }

    _receiptFormatterService.config.width =
      this._getCharectersLength(_selectedPrinter);
    let _zReportText = '';
    if (_selectedPrinter.type == 'web') {
      _zReportText = this._reportOutputHtmlFromXml(xmlDocument.ZREP);
    } else {
      _zReportText = this._reportOutputTextFromXml(
        _receiptFormatterService,
        xmlDocument.ZREP
      );
    }

    let printObject = this.printerService.getPrintObject(
      _selectedPrinter,
      _zReportText,
      true
    );

    return this.printerService.printScope([printObject]);
  }

  saveReceiptImage(receipt: IReceipt | OrderDTO) {
    const _receiptFormatterService = new ReceiptFormatterService();

    const order = new OrderDTO(receipt);
    order.storeName = this.terminalData.store.name;

    let receipt_header = null;
    let receipt_footer = null;
    let address = null;

    _receiptFormatterService.config.currency = '';
    _receiptFormatterService.config.ruler = '-';
    _receiptFormatterService.config.width = 45;

    let printLines = this._outputText(
      order,
      receipt_header,
      receipt_footer,
      false
    );

    const printText = _receiptFormatterService.create(printLines);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('download', 'check_1.png');

    this.printerService.textToPng(printText, '500px', 500).then((img) => {
      link.href = img.src.replace('image/png', 'image/octet-stream');
      link.click();
      document.body.removeChild(link);
    });
  }

  private _outputPreOrderText(receipt: OrderDTO, formatter: any): string {
    const lines: any[] = [];
    lines.push({
      type: 'text',
      value: 'ПОПЕРЕДНЕ ЗАМОВЛЕННЯ',
      align: 'center',
    });

    if (receipt.pointAddress) {
      lines.push({
        type: 'text',
        value: [receipt.pointAddress],
        align: 'center',
      });
    }

    lines.push({
      type: 'text',
      value: [
        receipt.opened
          ? DateUtils.display(receipt.opened, false)
          : receipt.created
          ? DateUtils.display(receipt.created, false)
          : '',
      ],
      align: 'center',
    });

    const productsLine = this._getProductsPrintText(receipt);
    if (productsLine == null) {
      return null;
    }

    lines.push(productsLine);

    // summary
    const summary: any[] = [];
    summary.push({
      name: 'СУМА',
      value: `${MoneyUtils.money(receipt.amount)} ${this.currencyService.name}`,
    });

    if (receipt.discount > 0) {
      const _loyaltyName = receipt.loyaltyProgram
        ? '   ' + receipt.loyaltyProgram.name
        : '   ЗНИЖКА';

      summary.push({
        name: _loyaltyName,
        value: MoneyUtils.money(receipt.discount),
      });
    }

    summary.push({
      name: 'ДО СПЛАТИ',
      value: `${receipt.total_str} ${this.currencyService.name}`,
    });

    lines.push({ type: 'properties', lines: summary });

    if (receipt.publicNote) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: 'Коментар:',
      });
      lines.push({
        type: 'text',
        value: receipt.publicNote,
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _outputWorkAreaLines(
    receipt: OrderDTO,
    work_area_id?: number,
    work_area_name?: string
  ): any[] {
    const lines: any[] = [];

    if (receipt.storeName) {
      lines.push({
        type: 'text',
        value: [receipt.storeName],
        align: 'center',
      });
    }

    if (work_area_name) {
      lines.push({
        type: 'text',
        value: [work_area_name],
        align: 'center',
      });
    }

    if (receipt.id > 0) {
      lines.push({
        type: 'text',
        value: `Замовлення № ${receipt.id}`,
      });
    }

    if (receipt.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: receipt.deadline
              ? DateUtils.formatCustom(receipt.deadline, 'dd MMM yyyy HH:mm')
              : '',
          },
        ],
      });
    }

    if (receipt.table) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Зала:',
            value: `${receipt.table.hallName}`,
          },
          {
            name: 'Стіл:',
            value: `${receipt.table.number} ${receipt.table.name}`,
          },
        ],
      });
      lines.push({ type: 'empty' });
    }

    const productsLine = this._getProductsPrintText(receipt, work_area_id);
    if (productsLine == null) {
      return [];
    }

    lines.push(productsLine);

    if (receipt.note) {
      lines.push({
        type: 'text',
        value: [receipt.note],
        align: 'center',
      });
    }

    lines.push({
      type: 'text',
      value: [
        receipt.opened
          ? DateUtils.display(receipt.opened, false)
          : receipt.created
          ? DateUtils.display(receipt.created, false)
          : '',
      ],
      align: 'center',
    });

    lines.push({ type: 'empty' });
    lines.push({ type: 'empty' });
    return lines;
  }

  private _outputText(
    receipt: OrderDTO,
    header: string,
    footer: boolean,
    isCopy = false,
    showOrderNumber = false
  ): any[] {
    const lines: any[] = [];

    // if (isCopy) {
    //   lines.push({
    //     type: 'text',
    //     value: '############################',
    //     align: 'center',
    //   });

    //   lines.push({
    //     type: 'text',
    //     value: 'КОПІЯ',
    //     align: 'center',
    //   });
    //   lines.push({
    //     type: 'text',
    //     value: '############################',
    //     align: 'center',
    //   });
    // }

    if (receipt.companyName) {
      lines.push({
        type: 'text',
        value: [`ФОП ${receipt.companyName}`],
        align: 'center',
      });
    }

    if (receipt.pointName) {
      lines.push({
        type: 'text',
        value: [receipt.pointName],
        align: 'center',
      });
    }

    if (receipt.pointAddress) {
      lines.push({
        type: 'text',
        value: [receipt.pointAddress],
        align: 'center',
      });
    }

    if (receipt.companyFiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ІД ' + receipt.companyFiscalNumber],
        align: 'center',
      });
    }

    if (receipt.taxRegisterFiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ФН ПРРО ' + receipt.taxRegisterFiscalNumber],
        align: 'center',
      });
    }

    if (receipt.fiscalNumber) {
      lines.push({
        type: 'text',
        value: ['ФН чека ' + receipt.fiscalNumber],
        align: 'center',
      });
    }

    if (receipt.cashier) {
      lines.push({
        type: 'text',
        value: ['касир ' + receipt.cashier],
        align: 'center',
      });
    }

    if (header) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: [header],
        align: 'center',
        padding: 2,
      });
    }

    if (receipt.deadline) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Дата гот',
            value: receipt.deadline
              ? DateUtils.formatCustom(receipt.deadline, 'dd MMM yyyy HH:mm')
              : '',
          },
        ],
      });
    }

    if (showOrderNumber ?? receipt.orderNumber) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Номер замовлення',
            value: receipt.orderNumber,
          },
        ],
      });
    }

    if (receipt.customerName) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Клієнт',
            value: receipt.customerName,
          },
        ],
      });
    }

    const productsLine = this._getProductsPrintText(receipt);
    if (productsLine == null) {
      return null;
    }

    lines.push(productsLine);
    let paymentType = receipt.iscashless ? 'Картка' : 'Готівка';

    // summary
    const summary: any[] = [];
    if (receipt.taxes && receipt.taxes.length > 0) {
      receipt.taxes.forEach((tax) => {
        summary.push({
          name: tax.NAME + ' ' + tax.LETTER + ' ' + tax.PRC + '%',
          value: tax.SUM,
        });
      });
    }

    summary.push({
      name: 'ЗАГАЛЬНА СУМА',
      value: `${MoneyUtils.money(receipt.amount)} ${this.currencyService.name}`,
    });

    if (receipt.discount > 0) {
      const _loyaltyName = receipt.loyaltyProgram
        ? '   ' + receipt.loyaltyProgram.name
        : '   ЗНИЖКА';

      summary.push({
        name: _loyaltyName,
        value: MoneyUtils.money(receipt.discount),
      });

      summary.push({
        name: 'РАЗОМ',
        value: MoneyUtils.money(receipt.noRndSum),
      });
    }

    if (receipt.rndSum) {
      summary.push({
        name: 'ЗАОКРУГЛЕННЯ',
        value: MoneyUtils.money(receipt.rndSum),
      });
    }

    summary.push({
      name: 'СУМА ДО СПЛАТИ',
      value: `${receipt.total_str} ${this.currencyService.name}`,
    });

    if (receipt.totalAmount > receipt.totalPaid) {
      summary.push({
        name: 'СПЛАЧЕНО',
        value: MoneyUtils.money(receipt.totalPaid),
      });
      summary.push({
        name: 'ДО СПЛАТИ',
        value: MoneyUtils.money(receipt.totalAmount - receipt.totalPaid),
      });
    }

    lines.push({ type: 'properties', lines: summary });
    lines.push({
      type: 'text',
      value: '--------------------------------',
      align: 'center',
    });

    const total: any[] = [];
    total.push({
      name: paymentType,
      value: `${receipt.total_str} ${this.currencyService.name}`,
    });

    if (receipt.providedSum > 0) {
      total.push({
        name: 'Сплачено',
        value: MoneyUtils.money(receipt.providedSum),
      });
    }
    if (receipt.remainsSum > 0) {
      total.push({
        name: 'Решта',
        value: MoneyUtils.money(receipt.remainsSum),
      });
    }
    lines.push({ type: 'properties', lines: total });
    lines.push({
      type: 'text',
      value: '--------------------------------',
      align: 'center',
    });
    lines.push({
      type: 'text',
      value: [
        receipt.opened
          ? DateUtils.display(receipt.opened)
          : receipt.created
          ? DateUtils.display(receipt.created)
          : '',
      ],
      align: 'center',
    });

    if (receipt.fiscalNumber) {
      if (receipt.testing) {
        lines.push({
          type: 'text',
          value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
          align: 'center',
        });
      } else {
        lines.push({
          type: 'text',
          value: ['ФІСКАЛЬНИЙ ЧЕК'],
          align: 'center',
        });
      }
    }

    if (receipt.taxRegisterName) {
      lines.push({
        type: 'text',
        value: [receipt.taxRegisterName],
        align: 'center',
      });

      lines.push({
        type: 'text',
        value: receipt.offline ? 'офлайн' : 'онлайн',
        align: 'center',
      });
    }

    // delivery
    if (receipt.delivery) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: ['Доставка'],
        align: 'center',
      });

      const _values: string[] = [];
      if (receipt.delivery.deliveryMethod) {
        _values.push(receipt.delivery.deliveryMethod.name);
      }

      let _address1 = '';

      if (receipt.delivery.street) {
        _address1 += `ул.${receipt.delivery.street}`;
      }

      if (receipt.delivery.houseNumber) {
        _address1 += ` д.${receipt.delivery.houseNumber}`;
      }

      if (receipt.delivery.houseCode) {
        _address1 += ` корп.${receipt.delivery.houseCode}`;
      }

      _values.push(_address1);

      let _address2 = '';
      if (receipt.delivery.flatNumber) {
        _address1 += `кв.${receipt.delivery.flatNumber}`;
      }
      if (receipt.delivery.floor) {
        _address1 += ` этаж ${receipt.delivery.floor}`;
      }

      _values.push(_address2);

      lines.push({
        type: 'text',
        value: _values,
        align: 'left',
      });

      if (receipt.delivery.deliveryMethod.price) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'вартість',
              value: MoneyUtils.money(receipt.delivery.deliveryMethod.price),
            },
          ],
        });
      }

      if (receipt.delivery.note) {
        lines.push({ type: 'empty' });
        lines.push({
          type: 'text',
          value: receipt.delivery.note,
          align: 'center',
        });
      }
    }

    if (receipt.publicNote) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: receipt.publicNote,
      });
    }

    if (footer) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: [footer],
        align: 'center',
      });
    }

    if (receipt.wish) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: receipt.wish,
      });
    }

    return lines;
  }

  private _getDeliveryLines(delivery: any) {
    const lines: any[] = [];

    lines.push({ type: 'empty' });

    if (delivery.store_name) {
      lines.push({
        type: 'text',
        value: [delivery.store_name],
        align: 'center',
      });
    }
    lines.push({
      type: 'text',
      value: ['Доставка'],
      align: 'center',
    });

    const _values: string[] = [];
    if (delivery.deliveryMethod) {
      _values.push(delivery.deliveryMethod.name);
    }

    let _address1 = '';

    if (delivery.street) {
      _address1 += `вул. ${delivery.street}`;
    }

    if (delivery.houseNumber) {
      _address1 += ` буд. ${delivery.houseNumber}`;
    }

    if (delivery.houseCode) {
      _address1 += ` корп. ${delivery.houseCode}`;
    }

    _values.push(_address1);

    let _address2 = '';
    if (delivery.flatNumber) {
      _address1 += `кв. ${delivery.flatNumber}`;
    }
    if (delivery.floor) {
      _address1 += ` поверх ${delivery.floor}`;
    }

    _values.push(_address2);

    lines.push({
      type: 'text',
      value: _values,
      align: 'left',
    });

    if (delivery.deliveryMethod.price) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: 'Вартість',
            value: MoneyUtils.money(delivery.deliveryMethod.price),
          },
        ],
      });
    }

    if (delivery.note) {
      lines.push({ type: 'empty' });
      lines.push({
        type: 'text',
        value: 'Коментар:',
      });

      lines.push({
        type: 'text',
        value: delivery.note,
        align: 'center',
      });
    }
  }

  private _checkOutputTextFromXml(
    formatter: any,
    xmlObject: {
      CHECKBODY: any;
      CHECKHEAD: any;
      CHECKTOTAL: number;
      CHECKPAY: any;
      CHECKTAX: any;
    }
  ): string {
    const devider = Array(formatter.config.width + 1).join('-');
    const deviderLine = {
      type: 'text',
      value: devider,
    };

    const headerLines = this._getTaxDocumentHeaderToPrintLines(
      xmlObject.CHECKHEAD
    );
    const productLines = this._checkBodyToPrintLines(xmlObject.CHECKBODY);
    const summaryLines = this._xmlCheckSummaryLines(
      xmlObject.CHECKPAY,
      xmlObject.CHECKTOTAL,
      deviderLine
    );

    let lines: any[] = [
      ...headerLines,
      ...productLines,
      ...summaryLines,
      ...[deviderLine],
    ];

    const orderDate = this._getTaxDocumentDate(
      xmlObject.CHECKHEAD.ORDERDATE,
      xmlObject.CHECKHEAD.ORDERTIME
    );
    lines.push({
      type: 'text',
      value: DateUtils.display(orderDate, true),
    });

    if (xmlObject.CHECKHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _getTaxDocumentHeader(header: any): string {
    let headerHtml = '';
    if (header.ORGNM) {
      headerHtml += `<div style="text-align: center;">${header.ORGNM}</div>`;
    }

    if (header.POINTNM) {
      headerHtml += `<div style="text-align: center;">${header.POINTNM}</div>`;
    }

    if (header.POINTADDR) {
      headerHtml += `<div style="text-align: center;">${header.POINTADDR}</div>`;
    }

    if (header.TIN) {
      headerHtml += `<div style="text-align: center;">${header.TIN}</div>`;
    }

    if (header.CASHREGISTERNUM) {
      headerHtml += `<div>ПРРО    ФН ${header.CASHREGISTERNUM}    ВН ${header.CASHDESKNUM}</div>`;
    }

    const docTypeName = this._getDocTypeName(header);
    headerHtml += `<div>${docTypeName}</div>`;
    headerHtml += `<div>     ФН ${header.ORDERTAXNUM}   ВН ${header.ORDERNUM}</div>`;
    if (header.TESTING) {
      headerHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (header.CASHIER) {
      headerHtml += `<div>Касир ${header.CASHIER}</div>`;
    }

    return headerHtml;
  }

  private _getDocTypeName(header: any): string {
    if (header.DOCTYPE == null || header.DOCTYPE == undefined) {
      return 'Z-ЗВІТ';
    }

    if (header.DOCTYPE == 0 && header.DOCSUBTYPE == 1) {
      return 'Видатковий чек (повернення)';
    }

    if (header.DOCTYPE == 0 && header.DOCSUBTYPE == 2) {
      return 'СЛУЖБОВЕ ВНЕСЕННЯ';
    }

    if (header.DOCTYPE == 0 && header.DOCSUBTYPE == 4) {
      return 'СЛУЖБОВА ВИДАЧА';
    }

    if (header.DOCTYPE == 0) {
      return 'Касовий чек';
    }
  }

  private _getTaxDocumentHeaderToPrintLines(header: any): any[] {
    const lines: any[] = [];
    const orgType = header.TIN ? 'ФОП' : 'ТОВ';

    if (header.ORGNM) {
      lines.push({
        type: 'text',
        value: `${orgType} ${header.ORGNM}`,
      });
    }

    if (header.POINTNM) {
      lines.push({
        type: 'text',
        value: header.POINTNM,
      });
    }

    if (header.POINTADDR) {
      lines.push({
        type: 'text',
        value: header.POINTADDR,
      });
    }

    if (header.TIN) {
      lines.push({
        type: 'text',
        value: `ІД ${header.TIN}`,
        align: 'center',
      });
    }
    const docTypeName = this._getDocTypeName(header);
    lines.push({
      type: 'text',
      value: docTypeName,
      align: 'center',
    });
    if (header.CASHREGISTERNUM) {
      lines.push({
        type: 'text',
        value: `ПРРО    ФН ${header.CASHREGISTERNUM}    ВН ${header.CASHDESKNUM}`,
      });
    }
    lines.push({
      type: 'text',
      value: `     ФН ${header.ORDERTAXNUM}   ВН ${header.ORDERNUM}`,
    });

    if (header.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
      });
    }

    if (header.CASHIER) {
      lines.push({
        type: 'text',
        value: `Касир ${header.CASHIER}`,
      });
    }

    return lines;
  }

  private _getHtmlDevider(): string {
    return `<div style="text-align: center;">-------------------------------</div>`;
  }

  private async _checkOutputHtmlFromXml(xmlObject: {
    CHECKBODY: any;
    CHECKHEAD: any;
    CHECKTOTAL: any;
    CHECKPAY: any;
    CHECKTAX: any;
  }): Promise<string> {
    let reportHtml = `<div style="width: 100%;font-size: 14px;">`;
    reportHtml += this._getTaxDocumentHeader(xmlObject.CHECKHEAD);
    const printProducts = this._checkBodyToPrintProducts(xmlObject.CHECKBODY);
    reportHtml += this._checkPrintProductsHtml(printProducts);
    reportHtml += this._checkPrintSummaryHtml(
      xmlObject.CHECKPAY,
      xmlObject.CHECKTOTAL
    );

    const orderDate = this._getTaxDocumentDate(
      xmlObject.CHECKHEAD.ORDERDATE,
      xmlObject.CHECKHEAD.ORDERTIME
    );

    reportHtml += `<div style="text-align: center;">${DateUtils.display(
      orderDate,
      true
    )}</div>`;

    if (xmlObject.CHECKHEAD.TESTING) {
      reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    reportHtml += `</div>`;
    return reportHtml;
  }

  private _getTaxDocumentDate(orderDate: string, orderTime: string): Date {
    const dateStr = orderDate.toString(); // 19112023 19-11-2023
    const timeStr = orderTime.toString(); // 13303 1:33:03

    const year = +dateStr.substring(dateStr.length - 4, dateStr.length);
    const month = +dateStr.substring(dateStr.length - 6, dateStr.length - 4);
    const day = +dateStr.substring(0, dateStr.length - 6);
    const sec = +timeStr.substring(timeStr.length - 2, timeStr.length);
    const mins = +timeStr.substring(timeStr.length - 4, timeStr.length - 2);
    const hours = +timeStr.substring(0, timeStr.length - 4);

    const date = new Date(year, month - 1, day, hours, mins, sec);
    return date;
  }

  private _checkBodyToPrintProducts(CHECKBODY: any): PrintProduct[] {
    let products: any = [];
    if (CHECKBODY.ROW) {
      if (CHECKBODY.ROW.length) {
        products = CHECKBODY.ROW;
      } else {
        products.push(CHECKBODY.ROW);
      }
    }

    return products.map((p) => {
      let exciseLabels: any = [];
      if (p.EXCISELABELS && p.EXCISELABELS.ROW) {
        if (p.EXCISELABELS.ROW.length) {
          for (let row of p.EXCISELABELS.ROW) {
            exciseLabels.push(row.EXCISELABEL);
          }
        } else {
          exciseLabels.push(p.EXCISELABELS.ROW.EXCISELABEL);
        }
      }

      return {
        amount: p.AMOUNT,
        code: p.CODE,
        cost: p.COST,
        letters: p.LETTERS,
        name: p.NAME,
        price: p.PRICE,
        uktZedCode: p.UKTZED,
        unitCode: p.UNITCD,
        unitName: p.UNITNM,
        exciseLabels: exciseLabels,
      } as PrintProduct;
    });
  }

  private _checkBodyToPrintLines(CHECKBODY: any): any[] {
    let products: any = [];
    if (CHECKBODY.ROW) {
      if (CHECKBODY.ROW.length) {
        products = CHECKBODY.ROW;
      } else {
        products.push(CHECKBODY.ROW);
      }
    }

    return [
      {
        type: 'table',
        lines: products.map((p) => {
          let exciseLabels: any[] = [];
          if (p.EXCISELABELS && p.EXCISELABELS.ROW) {
            if (p.EXCISELABELS.ROW.length) {
              for (let row of p.EXCISELABELS.ROW) {
                exciseLabels.push(row.EXCISELABEL);
              }
            } else {
              exciseLabels.push(p.EXCISELABELS.ROW.EXCISELABEL);
            }
          }

          return {
            item: p.NAME,
            qty: p.AMOUNT,
            cost: p.PRICE,
            total: p.COST,
            code: p.UKTZED,
            labels: exciseLabels.join(' '),
          };
        }),
      },
    ];
  }

  private _reportOutputTextFromXml(formatter: any, xmlObject: ZREP): string {
    let lines: any[] = [];

    const orgType = xmlObject.ZREPHEAD.TIN ? 'ФОП' : 'ТОВ';
    if (xmlObject.ZREPHEAD.ORGNM) {
      lines.push({
        type: 'text',
        value: `${orgType} ${xmlObject.ZREPHEAD.ORGNM}`,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.POINTNM) {
      lines.push({
        type: 'text',
        value: xmlObject.ZREPHEAD.POINTNM,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.POINTADDR) {
      lines.push({
        type: 'text',
        value: xmlObject.ZREPHEAD.POINTADDR,
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.TIN) {
      lines.push({
        type: 'text',
        value: '      ІД ' + xmlObject.ZREPHEAD.TIN,
      });
    }

    if (xmlObject.ZREPHEAD.CASHREGISTERNUM) {
      lines.push({
        type: 'text',
        value:
          'ПРРО    ФН ' +
          xmlObject.ZREPHEAD.CASHREGISTERNUM +
          '    ВН ' +
          xmlObject.ZREPHEAD.CASHDESKNUM,
      });
    }

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      lines.push({
        type: 'text',
        value:
          'Z-ЗВІТ  ФН ' +
          xmlObject.ZREPHEAD.ORDERTAXNUM +
          '   ВН ' +
          xmlObject.ZREPHEAD.ORDERNUM,
      });
    } else {
      lines.push({
        type: 'text',
        value: 'X-ЗВІТ',
      });
    }

    if (xmlObject.ZREPHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    }

    if (xmlObject.ZREPHEAD.CASHIER) {
      lines.push({
        type: 'text',
        value: 'Касир ' + xmlObject.ZREPHEAD.CASHIER,
      });
    }

    lines.push({
      type: 'text',
      value: '-------------------------------',
    });

    if (xmlObject.ZREPBODY) {
      if (xmlObject.ZREPBODY.SERVICEINPUT || xmlObject.ZREPBODY.SERVICEOUTPUT) {
        if (xmlObject.ZREPBODY.SERVICEINPUT) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'СЛУЖБОВЕ ВНЕСЕННЯ',
                value: MoneyUtils.money(xmlObject.ZREPBODY.SERVICEINPUT),
                align: 'right',
              },
            ],
          });
        }

        if (xmlObject.ZREPBODY.SERVICEOUTPUT) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'СЛУЖБОВА ВИДАЧА',
                value: MoneyUtils.money(xmlObject.ZREPBODY.SERVICEOUTPUT),
                align: 'right',
              },
            ],
          });
        }

        lines.push({
          type: 'text',
          value: '-------------------------------',
        });
      }
    }

    const realizeLines = this._reportRegtailzBlock(
      xmlObject.ZREPREALIZ,
      '     ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );

    const returnLines = this._reportRegtailzBlock(
      xmlObject.ZREPRETURN,
      '     ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    lines = [...lines, ...realizeLines, ...returnLines];

    const orderDate = this._getTaxDocumentDate(
      xmlObject.ZREPHEAD.ORDERDATE,
      xmlObject.ZREPHEAD.ORDERTIME
    );
    lines.push({
      type: 'text',
      value: DateUtils.display(orderDate, true),
    });

    if (xmlObject.ZREPHEAD.TESTING) {
      lines.push({
        type: 'text',
        value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
        align: 'center',
      });
    } else {
      lines.push({
        type: 'text',
        value: 'ФІСКАЛЬНИЙ Z-ЗВІТ',
        align: 'center',
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _reportRegtailzBlock(realizBlock: any, title: string): any[] {
    const lines: any[] = [];
    if (!realizBlock) {
      return lines;
    }

    lines.push({
      type: 'text',
      value: title,
    });

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Загальна сума',
          value: MoneyUtils.money(realizBlock.SUM),
          align: 'right',
        },
      ],
    });

    if (realizBlock.PAYFORMS && realizBlock.PAYFORMS.ROW) {
      if (realizBlock.PAYFORMS.ROW.length) {
        for (let pf of realizBlock.PAYFORMS.ROW) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: '- ' + pf.PAYFORMNM,
                value: MoneyUtils.money(pf.SUM),
                align: 'right',
              },
            ],
          });
        }
      } else {
        const payorm = realizBlock.PAYFORMS.ROW;
        lines.push({
          type: 'properties',
          lines: [
            {
              name: '- ' + payorm.PAYFORMNM,
              value: MoneyUtils.money(payorm.SUM),
              align: 'right',
            },
          ],
        });
      }
    }

    if (realizBlock.TAXES && realizBlock.TAXES.ROW) {
      lines.push({
        type: 'text',
        value: '     ПОДАТКИ',
      });
      if (realizBlock.TAXES.ROW.length) {
        for (let tax of realizBlock.TAXES.ROW) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: `${tax.NAME} ${tax.LETTER}   ${
                  tax.PRC
                }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
                value: MoneyUtils.money(tax.SUM),
                align: 'right',
              },
            ],
          });
        }
      } else {
        const tax = realizBlock.TAXES.ROW;
        lines.push({
          type: 'properties',
          lines: [
            {
              name: `${tax.NAME} ${tax.LETTER}   ${
                tax.PRC
              }% від ${MoneyUtils.money(tax.SOURCESUM)}`,
              value: MoneyUtils.money(tax.SUM),
              align: 'right',
            },
          ],
        });
      }
    }

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Чеків',
          value: realizBlock.ORDERSCNT,
          align: 'right',
        },
      ],
    });

    lines.push({
      type: 'text',
      value: '-------------------------------',
      align: 'center',
    });

    return lines;
  }

  private _reportOutputHtmlFromXml(xmlObject: ZREP): string {
    let reportHtml = `<div style="width: 100%;font-size: 14px;">`;

    const orgType = xmlObject.ZREPHEAD.TIN ? 'ФОП' : 'ТОВ';
    if (xmlObject.ZREPHEAD.ORGNM) {
      reportHtml += `<div style="text-align: center;">${orgType} ${xmlObject.ZREPHEAD.ORGNM}</div>`;
    }

    if (xmlObject.ZREPHEAD.POINTNM) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.POINTNM}</div>`;
    }

    if (xmlObject.ZREPHEAD.POINTADDR) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.POINTADDR}</div>`;
    }

    if (xmlObject.ZREPHEAD.TIN) {
      reportHtml += `<div style="text-align: center;">ІД ${xmlObject.ZREPHEAD.TIN}</div>`;
    }

    if (xmlObject.ZREPHEAD.IPN) {
      reportHtml += `<div style="text-align: center;">${xmlObject.ZREPHEAD.IPN}</div>`;
    }

    if (xmlObject.ZREPHEAD.CASHREGISTERNUM) {
      reportHtml += `<div>ПРРО    ФН ${xmlObject.ZREPHEAD.CASHREGISTERNUM}    ВН ${xmlObject.ZREPHEAD.CASHDESKNUM}</div>`;
    }

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      reportHtml += `<div>Z-ЗВІТ  ФН ${xmlObject.ZREPHEAD.ORDERTAXNUM}   ВН ${xmlObject.ZREPHEAD.ORDERNUM}</div>`;
    } else {
      reportHtml += `<div>X-ЗВІТ</div>`;
    }

    if (xmlObject.ZREPHEAD.TESTING) {
      reportHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (xmlObject.ZREPHEAD.CASHIER) {
      reportHtml += `<div>Касир ${xmlObject.ZREPHEAD.CASHIER}</div>`;
    }
    reportHtml += `<div style="text-align: center;">-------------------------------</div>`;

    if (xmlObject.ZREPBODY) {
      if (xmlObject.ZREPBODY.SERVICEINPUT || xmlObject.ZREPBODY.SERVICEOUTPUT) {
        if (xmlObject.ZREPBODY.SERVICEINPUT) {
          reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВЕ ВНЕСЕННЯ</td><td>${MoneyUtils.money(
            xmlObject.ZREPBODY.SERVICEINPUT
          )}</td></tr></table>`;
        }

        if (xmlObject.ZREPBODY.SERVICEOUTPUT) {
          reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВА ВИДАЧА</td><td>${MoneyUtils.money(
            xmlObject.ZREPBODY.SERVICEOUTPUT
          )}</td></tr></table>`;
        }

        reportHtml += `<div style="text-align: center;">-------------------------------</div>`;
      }
    }

    reportHtml += this._reportRegtailzBlockHtml(
      xmlObject.ZREPREALIZ,
      'ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    reportHtml += this._reportRegtailzBlockHtml(
      xmlObject.ZREPRETURN,
      'ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    const orderDate = this._getTaxDocumentDate(
      xmlObject.ZREPHEAD.ORDERDATE,
      xmlObject.ZREPHEAD.ORDERTIME
    );
    reportHtml += `<div style="text-align: center;">${DateUtils.display(
      orderDate
    )}</div>`;

    if (xmlObject.ZREPHEAD.ORDERTAXNUM) {
      if (xmlObject.ZREPHEAD.TESTING) {
        reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
      } else {
        reportHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ Z-ЗВІТ</div>`;
      }
    } else {
      reportHtml += `<div style="text-align: center;">X-ЗВІТ</div>`;
    }

    reportHtml += `</div>`;
    return reportHtml;
  }

  private _reportRegtailzBlockHtml(realizBlock: any, title: string): string {
    let block = '';
    if (!realizBlock) {
      return block;
    }

    block += `<div style="padding-left: 30px">${title}</div>`;
    block += `<table><tr><td style="width:100%">Загальна сума</td><td>${MoneyUtils.money(
      realizBlock.SUM
    )}</td></tr></table>`;

    if (realizBlock.PAYFORMS && realizBlock.PAYFORMS.ROW) {
      block += '<table>';

      if (realizBlock.PAYFORMS.ROW.length) {
        for (let pf of realizBlock.PAYFORMS.ROW) {
          block += `<tr><td style="width:100%"> - ${
            pf.PAYFORMNM
          }</td><td>${MoneyUtils.money(pf.SUM)}</td></tr>`;
        }
      } else {
        const payorm = realizBlock.PAYFORMS.ROW;
        block += `<tr><td style="width:100%"> - ${
          payorm.PAYFORMNM
        }</td><td>${MoneyUtils.money(payorm.SUM)}</td></tr>`;
      }

      block += '</table>';
    }

    block += `<table><tr><td style="width:100%">Чеків</td><td>${realizBlock.ORDERSCNT}</td></tr></table>`;
    block += `<div style="text-align: center;">-------------------------------</div>`;

    if (realizBlock.TAXES && realizBlock.TAXES.ROW) {
      block += `<div style="padding-left: 30px">ПОДАТКИ</div>`;

      block += '<table>';

      if (realizBlock.TAXES.ROW.length) {
        for (let tax of realizBlock.TAXES.ROW) {
          block += `<tr><td style="width:100%"> - ${tax.NAME} ${tax.LETTER}   ${
            tax.PRC
          }% від ${MoneyUtils.money(tax.SOURCESUM)}:</td><td>${MoneyUtils.money(
            tax.SUM
          )}</td></tr>`;
        }
      } else {
        const tax = realizBlock.TAXES.ROW;
        block += `<tr><td style="width:100%"> - ${tax.NAME} ${tax.LETTER}   ${
          tax.PRC
        }% від ${MoneyUtils.money(tax.SOURCESUM)}</td><td>${MoneyUtils.money(
          tax.SUM
        )}</td></tr>`;
      }

      block += '</table>';
      block += `<div style="text-align: center;">-------------------------------</div>`;
    }

    return block;
  }

  private _reportOutputHtmlFromObject(taxOrder: ITaxOrder): string {
    let reportHtml = `<div style="width: 100%;font-size: 12px;">`;

    const orgType = taxOrder.tin ? 'ФОП' : 'ТОВ';
    if (taxOrder.organizationName) {
      reportHtml += `<div style="text-align: center;">${orgType} ${taxOrder.organizationName}</div>`;
    }

    if (taxOrder.pointName) {
      reportHtml += `<div style="text-align: center;">${taxOrder.pointName}</div>`;
    }

    if (taxOrder.pointAddress) {
      reportHtml += `<div style="text-align: center;">${taxOrder.pointAddress}</div>`;
    }

    if (taxOrder.tin) {
      reportHtml += `<div style="text-align: center;">${taxOrder.tin}</div>`;
    }

    if (taxOrder.cashRegisterNum) {
      reportHtml += `<div>ПРРО    ФН ${taxOrder.cashRegisterNum}    ВН ${taxOrder.cashDeskNum}</div>`;
    }

    if (taxOrder.ORDERTAXNUM) {
      reportHtml += `<div>Z-ЗВІТ  ФН ${taxOrder.ORDERTAXNUM}   ВН ${taxOrder.orderNumber}</div>`;
    } else {
      reportHtml += `<div>X-ЗВІТ</div>`;
    }

    if (taxOrder.testing) {
      reportHtml += `<div>ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (taxOrder.cashier) {
      reportHtml += `<div>Касир ${taxOrder.cashier}</div>`;
    }
    reportHtml += `<div style="text-align: center;">-------------------------------</div>`;

    if (
      taxOrder.shiftTotals &&
      (taxOrder.shiftTotals.ServiceInput || taxOrder.shiftTotals.ServiceOutput)
    ) {
      if (taxOrder.shiftTotals.ServiceInput) {
        reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВЕ ВНЕСЕННЯ</td><td>${MoneyUtils.money(
          taxOrder.shiftTotals.ServiceInput
        )}</td></tr></table>`;
      }

      if (taxOrder.shiftTotals.ServiceOutput) {
        reportHtml += `<table><tr><td style="width:100%">СЛУЖБОВА ВИДАЧА</td><td>${MoneyUtils.money(
          taxOrder.shiftTotals.ServiceOutput
        )}</td></tr></table>`;
      }

      reportHtml += `<div style="text-align: center;">-------------------------------</div>`;
    }

    reportHtml += this._shiftTotalsOrderTypeHtml(
      taxOrder.shiftTotals?.Real,
      'ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    reportHtml += this._shiftTotalsOrderTypeHtml(
      taxOrder.shiftTotals?.Ret,
      'ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    reportHtml += `<div style="text-align: center;">${DateUtils.display(
      taxOrder.documentDate
    )}</div>`;

    if (taxOrder.testing) {
      reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
    }

    if (taxOrder.ORDERTAXNUM) {
      if (taxOrder.testing) {
        reportHtml += `<div style="text-align: center;">ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ</div>`;
      } else {
        reportHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ Z-ЗВІТ</div>`;
      }
    } else {
      reportHtml += `<div style="text-align: center;">X-ЗВІТ</div>`;
    }

    reportHtml += `</div>`;
    return reportHtml;
  }

  private _shiftTotalsOrderTypeHtml(
    orderType: ShiftTotalsOrderType,
    title: string
  ): string {
    let orderTypeHtml = '';
    if (!orderType) {
      return orderTypeHtml;
    }

    orderTypeHtml += `<div style="padding-left: 30px">     ${title}</div>`;
    orderTypeHtml += `<table><tr><td style="width:100%">Загальна сума</td><td>${MoneyUtils.money(
      orderType?.Sum
    )}</td></tr></table>`;

    if (orderType?.PayForm) {
      orderTypeHtml += '<table>';

      for (let pf of orderType.PayForm) {
        orderTypeHtml += `<tr><td style="width:100%"> - ${
          pf.PayFormName
        }</td><td>${MoneyUtils.money(pf.Sum)}</td></tr>`;
      }

      orderTypeHtml += '</table>';
    }

    if (orderType?.Tax) {
      orderTypeHtml += `<div style="padding-left: 30px">ПОДАТКИ</div>`;

      orderTypeHtml += '<table>';
      for (let tax of orderType.Tax) {
        orderTypeHtml += `<tr><td style="width:100%"> - ${tax.Name} ${
          tax.Letter
        }   ${tax.Prc}% від ${MoneyUtils.money(
          tax.SourceSum
        )}:</td><td>${MoneyUtils.money(tax.Sum)}</td></tr>`;
      }

      orderTypeHtml += '</table>';
      orderTypeHtml += `<div style="text-align: center;">-------------------------------</div>`;
    }

    orderTypeHtml += `<table><tr><td style="width:100%">Чеків</td><td>${
      orderType?.OrdersCount || 0
    }</td></tr></table>`;
    orderTypeHtml += `<div style="text-align: center;">-------------------------------</div>`;

    return orderTypeHtml;
  }

  private _getSalesReportHtml(report: any): string {
    let reportHtml = `<div style="text-align: center;">${report.store_name}</div>`;

    reportHtml += `<table style="width:100%">`;
    reportHtml += `<tr><td>Дата з</td><td style="text-align:right;">${DateUtils.formatCustom(
      report.date_form,
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `<tr><td>Дата до</td><td style="text-align:right;">${DateUtils.formatCustom(
      report.date_to,
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `<tr><td>Надруковано</td><td style="text-align:right;">${DateUtils.formatCustom(
      new Date(),
      'dd MMM yyyy HH:mm'
    )}</td></tr>`;
    reportHtml += `</table>`;

    reportHtml += `<table style="width:100%">`;
    report.products.forEach((p, i) => {
      reportHtml += `<tr><td>${i + 1}</td><td>${
        p.name
      }</td><td  style="text-align:right;">${
        p.quantity
      }</td><td  style="text-align:right;">${
        p.price
      }</td><td  style="text-align:right;">${p.amount}</td></tr>`;
    });
    reportHtml += `</table>`;
    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    if (report.groups && report.groups.length) {
      for (let g of report.groups) {
        reportHtml += `<div style="font-size: 16px; font-weight: bold;"">${g.name}</div>`;

        reportHtml += `<table style="width:100%">`;
        g.products.forEach((gp) => {
          reportHtml += `<tr><td>${gp.name}</td><td>${gp.quantity}</td><td>${gp.price}</td><td>${gp.amount}</td></tr>`;
        });
        reportHtml += `</table>`;

        reportHtml += `<table style="width:100%; font-size: 16px; font-weight: bold;">`;

        if (g.totaldiscount > 0) {
          reportHtml += `<tr><td colspan="4">Разом</td><td style="text-align: right">${g.amount}</td></tr>`;
          reportHtml += `<tr><td colspan="4">Знижка</td><td style="text-align: right">${g.totaldiscount}</td></tr>`;
        }

        reportHtml += `<tr><td colspan="4">Сума</td><td style="text-align: right">${g.income}</td></tr>`;
        reportHtml += `</table>`;
      }
    }

    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    if (report.ingredients) {
      reportHtml += `<table style="width:100%">`;
      report.ingredients.forEach((p) => {
        reportHtml += `<tr><td>${p.name}</td><td>${p.quantity}</td><td>${p.unit}</td></tr>`;
      });
      reportHtml += `</table>`;
    }

    reportHtml += `<table style="width:100%"><tr><td style="border-bottom: 1px solid black"></td></tr></table>`;
    reportHtml += `<table style="width:100%">`;
    reportHtml += `<tr><td>Товарів в чеку</td><td style="text-align: right">${report.avarageproductsperreceipt}</td></tr>`;
    reportHtml += `<tr><td>Середній чек</td><td style="text-align: right">${report.averagereceipttotal}</td></tr>`;
    reportHtml += `<tr><td>Всього</td><td style="text-align: right">${report.amount}</td></tr>`;
    reportHtml += `<tr><td>     Готівка</td><td style="text-align: right">${report.totalcash}</td></tr>`;
    reportHtml += `<tr><td>     Карта</td><td style="text-align: right">${report.totalcashless}</td></tr>`;
    reportHtml += `<tr><td>  Знижки</td><td style="text-align: right">- ${report.totaldiscount}</td></tr>`;
    reportHtml += `<tr><td>Виручка</td><td style="text-align: right">${report.income}</td></tr>`;
    reportHtml += `<tr><td>     Готівка</td><td style="text-align: right">${report.incomecash}</td></tr>`;
    reportHtml += `<tr><td>     Карта</td><td style="text-align: right">${report.incomecashless}</td></tr>`;
    reportHtml += `<tr><td>  Витрати</td><td style="text-align: right">- ${report.expenses}</td></tr>`;
    reportHtml += `<tr style="font-size: 16px;font-weight: bold;"><td>Готівка</td><td style="text-align: right">${report.totalcashbox}</td></tr>`;

    reportHtml += `</table>`;
    return reportHtml;
  }

  private _reportOutputText(
    formatter: any,
    taxOrder: ITaxOrder,
    header = true
  ): string {
    let lines: any[] = [];

    const orgType = taxOrder.tin ? 'ФОП' : 'ТОВ';

    if (header) {
      if (taxOrder.organizationName) {
        lines.push({
          type: 'text',
          value: [`${orgType} ${taxOrder.organizationName}`],
        });
      }

      if (taxOrder.pointName) {
        lines.push({
          type: 'text',
          value: [taxOrder.pointName],
        });
      }

      if (taxOrder.pointAddress) {
        lines.push({
          type: 'text',
          value: [taxOrder.pointAddress],
        });
      }

      if (taxOrder.tin) {
        lines.push({
          type: 'text',
          value: ['      ІД ' + taxOrder.tin],
        });
      }

      if (taxOrder.cashRegisterNum) {
        lines.push({
          type: 'text',
          value: [
            'ПРРО    ФН ' +
              taxOrder.cashRegisterNum +
              '    ВН ' +
              taxOrder.cashDeskNum,
          ],
        });
      }

      if (taxOrder.ORDERTAXNUM) {
        lines.push({
          type: 'text',
          value: [
            'Z-ЗВІТ  ФН ' +
              taxOrder.ORDERTAXNUM +
              '   ВН ' +
              taxOrder.orderNumber,
          ],
        });
      } else {
        lines.push({
          type: 'text',
          value: ['X-ЗВІТ'],
        });
      }

      if (taxOrder.testing) {
        lines.push({
          type: 'text',
          value: ['ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ'],
        });
      }
    }

    if (taxOrder.cashier) {
      lines.push({
        type: 'text',
        value: ['Касир ' + taxOrder.cashier],
      });
    }

    lines.push({
      type: 'text',
      value: '-------------------------------',
    });

    if (
      taxOrder.shiftTotals.ServiceInput ||
      taxOrder.shiftTotals.ServiceOutput
    ) {
      if (taxOrder.shiftTotals.ServiceInput) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'СЛУЖБОВЕ ВНЕСЕННЯ',
              value: MoneyUtils.money(taxOrder.shiftTotals.ServiceInput),
              align: 'right',
            },
          ],
        });
      }

      if (taxOrder.shiftTotals.ServiceOutput) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'СЛУЖБОВА ВИДАЧА',
              value: MoneyUtils.money(taxOrder.shiftTotals.ServiceOutput),
              align: 'right',
            },
          ],
        });
      }

      lines.push({
        type: 'text',
        value: '-------------------------------',
      });
    }

    const real = this._shiftTotalsOrderType(
      taxOrder.shiftTotals?.Real,
      '     ПІДСУМКИ РЕАЛІЗАЦІЇ'
    );
    const ret = this._shiftTotalsOrderType(
      taxOrder.shiftTotals?.Ret,
      '     ПІДСУМКИ ПОВЕРНЕННЯ'
    );

    lines = [...lines, ...real, ...ret];
    const _now = new Date();
    lines.push({
      type: 'text',
      value: [DateUtils.display(_now)],
      align: 'center',
    });

    if (taxOrder.ORDERTAXNUM) {
      if (taxOrder.testing) {
        lines.push({
          type: 'text',
          value: 'ТЕСТОВИЙ НЕФІСКАЛЬНИЙ ДОКУМЕНТ',
          align: 'center',
        });
      } else {
        lines.push({
          type: 'text',
          value: 'ФІСКАЛЬНИЙ Z-ЗВІТ',
          align: 'center',
        });
      }
    } else {
      lines.push({
        type: 'text',
        value: ['X-ЗВІТ'],
      });
    }

    var output = formatter.create(lines);
    return output;
  }

  private _shiftTotalsOrderType(
    orderType: ShiftTotalsOrderType,
    title: string
  ): any[] {
    const lines: any[] = [];
    lines.push({
      type: 'text',
      value: title,
      align: 'center',
    });

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Загальна сума',
          value: MoneyUtils.money(orderType?.Sum),
          align: 'right',
        },
      ],
    });

    if (!orderType) {
      return lines;
    }

    for (let pf of orderType.PayForm) {
      lines.push({
        type: 'properties',
        lines: [
          {
            name: '- ' + pf.PayFormName,
            value: MoneyUtils.money(pf.Sum),
            align: 'right',
          },
        ],
      });
    }

    if (orderType.Tax && orderType.Tax.length) {
      lines.push({
        type: 'text',
        value: 'ПОДАТКИ',
      });
      for (let tax of orderType.Tax) {
        lines.push({
          type: 'properties',
          lines: [
            {
              name: ` - ${tax.Name} ${tax.Letter}   ${
                tax.Prc
              }% від ${MoneyUtils.money(tax.SourceSum)}`,
              value: MoneyUtils.money(tax.Sum),
              align: 'right',
            },
          ],
        });
      }
    }

    lines.push({
      type: 'properties',
      lines: [
        {
          name: 'Чеків',
          value: orderType.OrdersCount?.toString(),
          align: 'right',
        },
      ],
    });

    lines.push({
      type: 'text',
      value: '-------------------------------',
      align: 'center',
    });

    return lines;
  }

  private _getProductsPrintText(
    receipt: OrderDTO,
    area_id: number = null
  ): any {
    if (!receipt.orderItems) {
      return null;
    }

    if (!area_id) {
      return {
        type: 'table',
        lines: receipt.orderItems.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
            code: p.uktZedCode,
            labels: p.exciseLabels,
          };
        }),
      };
    }

    const products = receipt.orderItems.filter((p) => p.workAreaId == area_id);
    if (products.length == 0) {
      return null;
    }

    return {
      type: 'table2',
      lines: products.map((p) => {
        return {
          item: p.name,
          qty: p.quantity,
          cost: p.price,
          total: p.amount,
        };
      }),
    };
  }

  private _getNext(): string {
    if (!this.terminalData.receipt_wishes) {
      return null;
    }

    const maxIndex = this.terminalData.receipt_wishes.length;
    if (maxIndex == 0) {
      return null;
    }

    if (this._lastWishIndex == 0 || this._lastWishIndex > 0) {
      this._lastWishIndex++;
    } else {
      this._lastWishIndex = 0;
    }

    if (this._lastWishIndex == maxIndex || this._lastWishIndex > maxIndex) {
      this._lastWishIndex = 0;
    }

    return this.terminalData.receipt_wishes[this._lastWishIndex];
  }

  private get _lastWishIndex(): number {
    const lastWishIndex = localStorage.getItem('LAST_WISH_INDEX');
    if (lastWishIndex) {
      return +lastWishIndex;
    } else {
      return 0;
    }
  }
  private set _lastWishIndex(lastWishIndex: number) {
    if (lastWishIndex == null || lastWishIndex == undefined) {
      return;
    }

    localStorage.setItem('LAST_WISH_INDEX', lastWishIndex.toString());
  }

  private _getRndWish(): string {
    if (!this.terminalData.receipt_wishes) {
      return null;
    }

    const maxIndex = this.terminalData.receipt_wishes.length;
    if (maxIndex == 0) {
      return null;
    }

    const rndWishIndex = this._getRndInteger(0, maxIndex);
    return this.terminalData.receipt_wishes[rndWishIndex];
  }

  private _getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private _getHeaderHtml(
    receipt: OrderDTO,
    header: string,
    isCopy = false,
    showOrderNumber = false
  ): string {
    let headerHtml = `<div style="width: 100%;font-size: 14px;">`;

    // if (isCopy) {
    //   headerHtml += `<div style="text-align: center;">#######################</div>`;
    //   headerHtml += `<div style="text-align: center;">КОПІЯ</div>`;
    //   headerHtml += `<div style="text-align: center;">#######################</div>`;
    // }

    if (receipt.companyName) {
      headerHtml += `<div style="text-align: center;">${receipt.companyName}</div>`;
    }

    if (receipt.pointName) {
      headerHtml += `<div style="text-align: center;">${receipt.pointName}</div>`;
    }

    if (receipt.pointAddress) {
      headerHtml += `<div style="text-align: center;">${receipt.pointAddress}</div>`;
    }

    if (receipt.companyFiscalNumber) {
      headerHtml += `<div style="text-align: center;">ІД ${receipt.companyFiscalNumber}</div>`;
    }

    if (receipt.taxRegisterFiscalNumber) {
      headerHtml += `<div style="text-align: center;">ФН ПРРО ${receipt.taxRegisterFiscalNumber}</div>`;
    }

    if (receipt.fiscalNumber) {
      headerHtml += `<div style="text-align: center;">ФН чека ${receipt.fiscalNumber}</div>`;
    }

    if (receipt.cashier) {
      headerHtml += `<div style="text-align: center;">касир ${receipt.cashier}</div>`;
    }

    if (header) {
      headerHtml += `<div style="text-align: center;">&nbsp;</div>`;
      headerHtml += `<div style="text-align: center;">${header}</div>`;
    }

    if (receipt.customerName) {
      headerHtml += `<table style="width:100%"><tr><td>Кліент</td><td style="text-align: right;">${receipt.customerName}</td></tr></table>`;
    }

    if (showOrderNumber && receipt.orderNumber) {
      headerHtml += `<table style="width:100%"><tr><td>Номер замовлення</td><td style="text-align: right;">${receipt.orderNumber}</td></tr></table>`;
    }

    if (receipt.deadline) {
      headerHtml += `<table style="width:100%"><tr><td>Дата гот</td><td style="text-align: right;">${
        receipt.deadline
          ? DateUtils.formatCustom(receipt.deadline, 'dd-MM-yyyy')
          : ''
      }</td></tr></table>`;
    }

    headerHtml += `</div>`;
    headerHtml += `<div style="text-align: center;">&nbsp;</div>`;
    return headerHtml;
  }

  private _getOrderHeaderHtml(
    receipt: OrderDTO,
    header: string,
    isCopy = false,
    showOrderNumber = false
  ): string {
    let headerHtml = `<div style="width: 100%;font-size: 14px;">`;

    // if (isCopy) {
    //   headerHtml += `<div style="text-align: center;">#######################</div>`;
    //   headerHtml += `<div style="text-align: center;">КОПІЯ</div>`;
    //   headerHtml += `<div style="text-align: center;">#######################</div>`;
    // }

    if (receipt.storeName) {
      headerHtml += `<div style="text-align: center;">${receipt.storeName}</div>`;
    }

    if (receipt.pointAddress) {
      headerHtml += `<div style="text-align: center;">${receipt.pointAddress}</div>`;
    }

    if (receipt.cashier) {
      headerHtml += `<table style="width:100%"><tr><td>Касир</td><td style="text-align: right;">${receipt.cashier}</td></tr></table>`;
    }

    if (receipt.customerName) {
      headerHtml += `<table style="width:100%"><tr><td>Кліент</td><td style="text-align: right;">${receipt.customerName}</td></tr></table>`;
    }

    if (showOrderNumber && receipt.orderNumber) {
      headerHtml += `<table style="width:100%"><tr><td>Номер замовлення</td><td style="text-align: right;">${receipt.orderNumber}</td></tr></table>`;
    }

    if (receipt.deadline) {
      headerHtml += `<table style="width:100%"><tr><td>Дата гот</td><td style="text-align: right;">${
        receipt.deadline
          ? DateUtils.formatCustom(receipt.deadline, 'dd MMM yyyy HH:mm')
          : ''
      }</td></tr></table>`;
    }

    if (header) {
      headerHtml += `<div style="text-align: center;">${header}</div>`;
    }

    headerHtml += `</div>`;
    headerHtml += `<div style="text-align: center;">&nbsp;</div>`;
    return headerHtml;
  }

  private _getReceiptSummary(receipt: OrderDTO) {
    let summaryHtml = `<table style="width:100%">`;

    let paymentType = receipt.iscashless ? 'Картка' : 'Готівка';
    if (receipt.taxes && receipt.taxes.length > 0) {
      receipt.taxes.forEach((tax) => {
        summaryHtml += `<div style="text-align: center;">${tax.NAME} ${tax.LETTER} ${tax.PRC}%  ${tax.SUM}</div>`;
      });
    }

    if (receipt.discount > 0) {
      summaryHtml += `<tr>
      <td style="width: 100%">ЗАГАЛЬНА СУМА:</td>
      <td style="text-align:right;">${MoneyUtils.money(receipt.amount)} ${
        this.currencyService.name
      }</td>
        </tr>`;

      const _loyaltyName = receipt.loyaltyProgram
        ? '   ' + receipt.loyaltyProgram.name
        : 'ЗНИЖКА';

      summaryHtml += `<tr>
        <td style="width: 100%">${_loyaltyName}:</td>
        <td style="text-align:right;">${MoneyUtils.money(receipt.discount)} ${
        this.currencyService.name
      }</td>
          </tr>`;

      summaryHtml += `<tr>
        <td style="width: 100%">РАЗОМ:</td>
        <td style="text-align:right;">${MoneyUtils.money(receipt.noRndSum)} ${
        this.currencyService.name
      }</td>
          </tr>`;
    }

    if (receipt.rndSum) {
      summaryHtml += `<tr>
      <td style="width: 100%">ЗАОКРУГЛЕННЯ:</td>
      <td style="text-align:right;">${MoneyUtils.money(receipt.rndSum)} ${
        this.currencyService.name
      }</td>
        </tr>`;
    }

    summaryHtml += `<tr>
    <td style="width: 100%">СУМА ДО СПЛАТИ:</td>
    <td style="text-align:right;">${MoneyUtils.money(receipt.total_str)} ${
      this.currencyService.name
    }</td>
      </tr>`;

    if (receipt.totalPaid) {
      summaryHtml += `<tr>
        <td style="width: 100%">СПЛАЧЕНО:</td>
          <td style="text-align:right;">${MoneyUtils.money(
            receipt.totalPaid
          )}</td>
        </tr>`;

      summaryHtml += `<tr>
        <td style="width: 100%">ДО СПЛАТИ:</td>
          <td style="text-align:right;">${MoneyUtils.money(
            receipt.totalAmount - receipt.totalPaid
          )}</td>
        </tr>`;
    }

    summaryHtml += `<tr><td style="width: 100%; border-bottom: 1px solid black;" colspan="2"></td></tr>`;
    // summaryHtml += `<tr>
    //   <td style="width: 100%">${paymentType}:</td>
    //   <td style="text-align:right;">${MoneyUtils.money(receipt.total_str)}</td>
    //     </tr>`;

    if (receipt.providedSum > 0) {
      summaryHtml += `<tr>
      <td style="width: 100%">Сплачено:</td>
      <td style="text-align:right;">${MoneyUtils.money(
        receipt.providedSum
      )}</td>
        </tr>`;
    }
    if (receipt.remainsSum > 0) {
      summaryHtml += `<tr>
      <td style="width: 100%">Решта:</td>
      <td style="text-align:right;">${MoneyUtils.money(receipt.remainsSum)}</td>
        </tr>`;
    }

    summaryHtml += `</table>`;
    summaryHtml += `<div>`;
    summaryHtml += `<div style="text-align: center;">${
      receipt.opened
        ? DateUtils.display(receipt.opened, false)
        : receipt.created
        ? DateUtils.display(receipt.created, false)
        : ''
    }</div>`;

    if (receipt.fiscalNumber) {
      summaryHtml += `<div style="text-align: center;">ФІСКАЛЬНИЙ ЧЕК</div>`;
    }

    if (receipt.taxRegisterName) {
      summaryHtml += `<div style="text-align: center;">${receipt.taxRegisterName}</div>`;
    }

    summaryHtml += `</div>`;
    return summaryHtml;
  }

  private _getOrderSummary(receipt: OrderDTO) {
    let summaryHtml = `<table style="width:100%">`;

    let paymentType = receipt.iscashless ? 'Картка' : 'Готівка';
    if (receipt.taxes && receipt.taxes.length > 0) {
      receipt.taxes.forEach((tax) => {
        summaryHtml += `<div style="text-align: center;">${tax.NAME} ${tax.LETTER} ${tax.PRC}%  ${tax.SUM}</div>`;
      });
    }
    summaryHtml += `<tr>
    <td style="width: 100%">СУМА:</td>
    <td style="text-align:right;">${MoneyUtils.money(receipt.amount)} ${
      this.currencyService.name
    }</td>
      </tr>`;

    if (receipt.discount > 0) {
      const _loyaltyName = receipt.loyaltyProgram
        ? '   ' + receipt.loyaltyProgram.name
        : 'ЗНИЖКА';

      summaryHtml += `<tr>
        <td style="width: 100%">${_loyaltyName}:</td>
        <td style="text-align:right;">${MoneyUtils.money(receipt.discount)}</td>
          </tr>`;

      summaryHtml += `<tr>
      <td style="width: 100%">ДО СПЛАТИ:</td>
      <td style="text-align:right;">${MoneyUtils.money(receipt.total_str)}  ${
        this.currencyService.name
      }</td>
        </tr>`;
    }

    summaryHtml += `<tr>
        <td style="width: 100%">СПЛАЧЕНО:</td>
          <td style="text-align:right;">${
            receipt.totalPaid ? MoneyUtils.money(receipt.totalPaid) : '0,00'
          }</td>
        </tr>`;

    if (receipt.totalPaid && receipt.totalPaid < receipt.totalAmount) {
      summaryHtml += `<tr>
        <td style="width: 100%">ДО ПОВНОЇ ОПЛАТИ:</td>
          <td style="text-align:right;">${MoneyUtils.money(
            receipt.totalAmount - receipt.totalPaid
          )}</td>
        </tr>`;
    }

    summaryHtml += `</table>`;
    return summaryHtml;
  }

  private _getReceiptComment(receipt: OrderDTO) {
    if (!receipt.publicNote) {
      return '';
    }

    return `<div style="width: 100%;padding-top: 10px"><span>${receipt.publicNote}</span></div>`;
  }

  private _getDelivery(receipt: OrderDTO) {
    let deliveryHtml = `<div style="width: 100%">`;

    // delivery
    if (receipt.delivery) {
      deliveryHtml += `<div style="text-align: center;">------------------------------</div>`;
      deliveryHtml += `<div style="text-align: center;">Доставка</div>`;

      if (receipt.delivery.deliveryMethod) {
        deliveryHtml += `<div style="text-align: center;">${receipt.delivery.deliveryMethod.name}</div>`;
      }

      let _address1 = '';

      if (receipt.delivery.street) {
        _address1 += `ул.${receipt.delivery.street}`;
      }

      if (receipt.delivery.houseNumber) {
        _address1 += ` д.${receipt.delivery.houseNumber}`;
      }

      if (receipt.delivery.houseCode) {
        _address1 += ` корп.${receipt.delivery.houseCode}`;
      }

      deliveryHtml += `<div style="text-align: center;">${_address1}</div>`;

      let _address2 = '';
      if (receipt.delivery.flatNumber) {
        _address1 += `кв.${receipt.delivery.flatNumber}`;
      }
      if (receipt.delivery.floor) {
        _address1 += ` этаж ${receipt.delivery.floor}`;
      }
      deliveryHtml += `<div style="text-align: center;">${_address2}</div>`;

      if (receipt.delivery.price) {
        deliveryHtml += `<div style="text-align: center;">вартість ${MoneyUtils.money(
          receipt.delivery.price
        )}</div>`;
      } else if (receipt.delivery.deliveryMethod.price) {
        deliveryHtml += `<div style="text-align: center;">вартість ${MoneyUtils.money(
          receipt.delivery.deliveryMethod.price
        )}</div>`;
      }

      if (receipt.delivery.note) {
        deliveryHtml += `<div style="text-align: center;">------------------------------</div>`;
        deliveryHtml += `<div style="text-align: center;">${receipt.delivery.note}</div>`;
      }
    }

    deliveryHtml += `</div>`;
    return deliveryHtml;
  }
  private _getFooter(footer: boolean, receipt: OrderDTO) {
    let footerHtml = `<div style="width: 100%">`;
    if (footer) {
      footerHtml += `<div style="text-align: center;">&nbsp;</div>`;
      footerHtml += `<div style="text-align: center;">${footer}</div>`;
    }

    if (receipt.taxRegisterFiscalNumber) {
      if (receipt.offline) {
        footerHtml += `<div style="text-align: center;">офлайн</div>`;
      } else {
        footerHtml += `<div style="text-align: center;">онлайн</div>`;
      }
    }

    footerHtml += `</div>`;
    return footerHtml;
  }
  private _getWish(receipt: OrderDTO) {
    let wishHtml = ``;
    if (receipt.wish) {
      wishHtml += `<div>&nbsp;</div>`;
      wishHtml += `<div style="width:100%;text-align:center;font-size:14px">${receipt.wish}</div>`;
    }
    return wishHtml;
  }
  private _productsHtml(receipt: OrderDTO, hasBorders = true): string {
    let tableHtml = `<table style="width:100%;border-top:1px solid black;border-bottom:1px solid black;margin-bottom:8px;margin-top:8px;"><tr style="font-size: 10px; font-weight: bold"><td>Назва</td><td>Кіл-ть</td><td>Ціна</td><td>Разом</td></tr>`;

    if (!receipt.orderItems) {
      return tableHtml;
    }

    for (let p of receipt.orderItems) {
      tableHtml += `<tr><td style="width: 100%;">${p.name}</td><td>${
        p.quantity
      }</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.price
      )}</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.amount
      )}</td></tr>`;

      if (p.uktZedCode) {
        tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">УКТЗЕД КОД:${p.uktZedCode}</td></tr>`;
      }

      if (p.exciseLabels && p.exciseLabels.length) {
        for (let el of p.exciseLabels) {
          tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">${el}</td></tr>`;
        }
      }
    }

    tableHtml += `</table>`;
    return tableHtml;
  }

  private _checkPrintProductsHtml(products: PrintProduct[]): string {
    let tableHtml = ``;
    if (!products || !products.length) {
      return tableHtml;
    }

    tableHtml = `<table style="width:100%;border-top:1px solid black;border-bottom:1px solid black;margin-bottom:8px;margin-top:8px;"><tr style="font-size: 10px; font-weight: bold"><td>Назва</td><td>Кіл-ть</td><td>Ціна</td><td>Разом</td></tr>`;

    for (let p of products) {
      tableHtml += `<tr><td style="width: 100%;">${p.name}</td><td>${
        p.amount
      }</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.price
      )}</td><td style="padding:0 3px;text-align:right;">${MoneyUtils.money(
        p.cost
      )}</td></tr>`;

      if (p.uktZedCode) {
        tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">УКТЗЕД КОД:${p.uktZedCode}</td></tr>`;
      }

      if (p.exciseLabels && p.exciseLabels.length) {
        for (let el of p.exciseLabels) {
          tableHtml += `<tr><td colspan="4" style="width: 100%;font-size:10px;padding-left: 20px;">${el}</td></tr>`;
        }
      }
    }

    tableHtml += `</table>`;
    return tableHtml;
  }

  private _checkPrintSummaryHtml(CHECKPAY: any, CHECKTOTAL: any): string {
    let summaryHtml = `<table style="width:100%">`;

    if (CHECKTOTAL.DISCOUNTSUM > 0) {
      summaryHtml += `<tr>
        <td style="width: 100%">СУМА:</td>
        <td style="text-align:right;">${MoneyUtils.money(
          CHECKTOTAL.SUM + CHECKTOTAL.DISCOUNTSUM
        )}</td>
          </tr>`;
      summaryHtml += `<tr>
        <td style="width: 100%">ЗНИЖКА:</td>
        <td style="text-align:right;">${MoneyUtils.money(
          CHECKTOTAL.DISCOUNTSUM
        )}</td>
          </tr>`;
    }

    if (CHECKTOTAL.RNDSUM) {
      summaryHtml += `<tr>
        <td style="width: 100%">ЗАОКРУГЛЕННЯ:</td>
        <td style="text-align:right;">${MoneyUtils.money(
          CHECKTOTAL.RNDSUM
        )}</td>
          </tr>`;
    }

    summaryHtml += `<tr>
      <td style="width: 100%">ДО СПЛАТИ:</td>
      <td style="text-align:right;">${MoneyUtils.money(CHECKTOTAL.SUM)}</td>
        </tr>`;

    summaryHtml += `<tr>
        <td style="width: 100%">${CHECKPAY.ROW.PAYFORMNM}:</td>
        <td style="text-align:right;">${MoneyUtils.money(CHECKPAY.ROW.SUM)}</td>
          </tr>`;

    summaryHtml += `<tr><td style="width: 100%; border-bottom: 1px solid black;" colspan="2"></td></tr>`;

    // if (receipt.providedSum > 0) {
    //   summaryHtml += `<tr>
    //     <td style="width: 100%">Сплачено:</td>
    //     <td style="text-align:right;">${MoneyUtils.money(
    //       receipt.providedSum
    //     )}</td>
    //       </tr>`;
    // }
    // if (receipt.remainsSum > 0) {
    //   summaryHtml += `<tr>
    //     <td style="width: 100%">Решта:</td>
    //     <td style="text-align:right;">${MoneyUtils.money(
    //       receipt.remainsSum
    //     )}</td>
    //       </tr>`;
    // }

    summaryHtml += `</table>`;
    return summaryHtml;
  }

  private _xmlCheckSummaryLines(
    CHECKPAY: any,
    CHECKTOTAL: any,
    devider: any
  ): any[] {
    const summary1: any[] = [];
    const summary2: any[] = [];

    const discount = CHECKTOTAL.DISCOUNTSUM || 0;

    if (CHECKTOTAL.DISCOUNTSUM > 0 || CHECKTOTAL.RNDSUM) {
      summary1.push({
        name: 'СУМА',
        value: MoneyUtils.money(CHECKTOTAL.SUM + discount),
      });

      if (CHECKTOTAL.DISCOUNTSUM > 0) {
        summary1.push({
          name: 'ЗНИЖКА',
          value: MoneyUtils.money(CHECKTOTAL.DISCOUNTSUM),
        });
      }

      if (CHECKTOTAL.RNDSUM) {
        summary1.push({
          name: 'ЗАОКРУГЛЕННЯ',
          value: MoneyUtils.money(CHECKTOTAL.RNDSUM),
        });
      }
    }

    summary1.push({
      name: 'ДО СПЛАТИ',
      value: MoneyUtils.money(CHECKTOTAL.SUM),
    });

    let payforms: any[] = [];
    if (CHECKPAY?.ROW) {
      if (CHECKPAY.ROW.length) {
        payforms = CHECKPAY.ROW;
      } else {
        payforms.push(CHECKPAY.ROW);
      }
    }

    for (let pf of payforms) {
      summary2.push({
        name: pf.PAYFORMNM,
        value: MoneyUtils.money(pf.SUM),
      });

      if (pf.PROVIDED > 0) {
        summary2.push({
          name: 'Отримано',
          value: MoneyUtils.money(pf.PROVIDED),
        });

        summary2.push({
          name: 'Решта',
          value: MoneyUtils.money(pf.REMAINS),
        });
      }
    }

    const lines: any[] = [];
    lines.push({ type: 'properties', lines: summary1 });
    lines.push(devider);
    lines.push({ type: 'properties', lines: summary2 });

    return lines;
  }

  _getSalesReportString(
    report: any,
    formatter: ReceiptFormatterService
  ): string {
    report.store_name = this.terminalData.store.name;
    let lines: any[] = [
      {
        type: 'text',
        value: [report.store_name || ''],
        align: 'center',
      },
      { type: 'empty' },
      {
        type: 'properties',
        lines: [
          {
            name: 'Дата з',
            value: DateUtils.formatCustom(
              report.date_form,
              'dd MMM yyyy HH:mm'
            ),
          },
          {
            name: 'Дата до',
            value: DateUtils.formatCustom(report.date_to, 'dd MMM yyyy HH:mm'),
          },
          {
            name: 'Надруковано',
            value: DateUtils.formatCustom(new Date(), 'dd MMM yyyy HH:mm'),
          },
        ],
      },
      { type: 'empty' },
      {
        type: 'table',
        lines: report.products.map((p) => {
          return {
            item: p.name,
            qty: p.quantity,
            cost: p.price,
            total: p.amount,
          };
        }),
      },
    ];

    if (report.groups && report.groups.length) {
      for (let g of report.groups) {
        lines.push({ type: 'empty' });
        lines.push({
          type: 'text',
          value: [g.name || ''],
        });
        lines.push({
          type: 'table',
          lines: g.products.map((p) => {
            return {
              item: p.name,
              qty: p.quantity,
              cost: p.price,
              total: p.amount,
            };
          }),
        });

        if (g.totaldiscount > 0) {
          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'Разом',
                value: g.amount,
              },
            ],
          });

          lines.push({
            type: 'properties',
            lines: [
              {
                name: 'Знижка',
                value: g.totaldiscount,
              },
            ],
          });
        }

        lines.push({
          type: 'properties',
          lines: [
            {
              name: 'Сума',
              value: g.income,
            },
          ],
        });
      }
    }

    lines = [
      ...lines,
      ...[
        { type: 'empty' },
        {
          type: 'table',
          lines: !report.ingredients
            ? []
            : report.ingredients.map((p) => {
                return {
                  item: p.name,
                  qty: p.quantity,
                  unit: p.unit,
                };
              }),
        },
        {
          type: 'properties',
          lines: [
            {
              name: 'Товарів в чеку',
              value: report.avarageproductsperreceipt,
            },
            {
              name: 'Середній чек',
              value: report.averagereceipttotal,
            },
          ],
        },
        { type: 'empty' },
        {
          type: 'properties',
          lines: [
            { name: 'Всього', value: report.amount },
            {
              name: '     Готівка',
              value: report.totalcash,
            },
            {
              name: '     Карта',
              value: report.totalcashless,
            },
            {
              name: '  Знижки',
              value: '-' + report.totaldiscount,
            },

            {
              name: 'Виручка',
              value: report.income,
            },
            {
              name: '     Готівка',
              value: report.incomecash,
            },
            {
              name: '     Карта',
              value: report.incomecashless,
            },
            {
              name: '  Витрати',
              value: '-' + report.expenses,
            },
            {
              name: 'Готівка',
              value: report.totalcashbox,
            },
          ],
        },
      ],
    ];

    return formatter.create(lines);
  }

  private _getDeliveryHtml(order: IReceipt) {
    let deliveryPrint = `<div style="text-align: center;font-size:18px">${order.store_name}</div>`;
    deliveryPrint += `<div style="text-align: center;font-size:18px">ДОСТАВКА</div>`;

    const delivery = order.delivery;
    deliveryPrint += `<div>Адреса доставки</div>`;
    deliveryPrint += `<table style="width:100%">`;
    deliveryPrint += `<tr><td>Місто</td><td style="text-align: right">${delivery.deliveryMethod.name}</td></tr>`;
    deliveryPrint += `<tr><td>Вулиця</td><td style="text-align: right">${delivery.street}</td></tr>`;
    deliveryPrint += `<tr><td>Будинок</td><td style="text-align: right">${delivery.houseNumber}</td></tr>`;
    deliveryPrint += `<tr><td>Корпус</td><td style="text-align: right">${delivery.houseCode}</td></tr>`;
    deliveryPrint += `<tr><td>Квартира</td><td style="text-align: right">${delivery.flatNumber}</td></tr>`;
    deliveryPrint += `<tr><td>Поверх</td><td style="text-align: right">${delivery.floor}</td></tr>`;
    deliveryPrint += `</table>`;
    deliveryPrint += `<div style="text-align: center">--------------------------------</div>`;

    if (delivery.note) {
      deliveryPrint += `<div>Коментар</div>`;
      deliveryPrint += `<div>${delivery.note}</div>`;
      deliveryPrint += `<div style="text-align: center">--------------------------------</div>`;
    }

    deliveryPrint += `<table style="width:100%">`;
    deliveryPrint += `<tr><td>ВАРТІСТЬ</td><td style="text-align: right">${MoneyUtils.money(
      delivery.price
    )}</td></tr>`;
    deliveryPrint += `</table>`;

    return deliveryPrint;
  }
}
