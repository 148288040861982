import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ru, uk } from 'date-fns/locale';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
  defaultLeng = 'ru';
  private _translate: any;
  private _translateSub: Observable<any>;
  private _languageSub: Observable<any>;
  private _currentLang: string;

  constructor(private _http: HttpClient, private plt: Platform) {
    this.setLanguage('uk');

    const platformObs = from(this.plt.ready());
    this._translateSub = platformObs.pipe(
      switchMap(() => {
        if (this._translate) {
          return of(this._translate);
        } else {
          return this._languageSub;
        }
      }),
      map((res) => {
        this._translate = res;
        return res;
      })
    );
  }

  getDateLocaleSetting() {
    const _currentLang = this.getCurrentLang();
    if (_currentLang == 'ru') {
      return { locale: ru };
    } else if (_currentLang == 'uk') {
      return { locale: uk };
    } else {
      return {};
    }
  }

  setLanguage(lang: 'ru' | 'uk') {
    this._loadLanguage(lang);
    this._currentLang = lang;
  }

  getCurrentLang() {
    return this._currentLang ? this._currentLang : 'uk';
  }

  getCulture() {
    console.log('getCulture');

    switch (this._currentLang) {
      case 'uk':
        return 'uk-UA';
      case 'ru':
        return 'ru-RU';
      case 'eu':
        return 'en-EU';
      case 'pl':
        return 'pl-PL';

      default:
        return 'uk-UA';
    }
  }

  get(key: string[] | string): Observable<string | any> {
    return this._translateSub.pipe(
      map((transl) => {
        if (!key || !this._translate) {
          return {};
        } else {
          var res = {};
          if (typeof key == 'string') {
            return this._getByKey(key);
          } else if (typeof key == 'object') {
            key.forEach((k) => {
              const value = this._getByKey(k);
              res[k] = value;
            });
          }

          return res;
        }
      })
    );
  }

  private _getByKey(key: string): string {
    const parts = key.split('.');
    let res;
    parts.forEach((part, index) => {
      if (index == 0) {
        res = this._translate[part];
      } else {
        if (res) {
          res = res[part];
        } else {
          return key;
        }
      }
    });

    if (typeof res == 'string') {
      return res;
    } else {
      return key;
    }
  }

  private _loadLanguage(lang: string) {
    lang = lang || this.defaultLeng;
    this._translate = null;
    this._languageSub = this._http.get<any>(
      `../../assets/i18n/${lang}.json` + '?cb=' + new Date().getTime()
    );
  }
}
