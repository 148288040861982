import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { Platform } from '@ionic/angular';

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.Offline
  );

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  constructor(private network: Network, private plt: Platform) {
    this.plt.ready().then((platform) => {
      if (platform == 'dom') {
        this._initializeDomEvents();
      } else {
        this._initializeNetworkEvents();
      }
    });
  }

  private _initializeDomEvents() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe((e) => {
      console.log('Online...');
      this._updateNetworkStatus(ConnectionStatus.Online);
    });

    this.offlineEvent.subscribe((e) => {
      console.log('Offline...');
      this._updateNetworkStatus(ConnectionStatus.Offline);
    });
  }

  private _initializeNetworkEvents() {
    this.network.onDisconnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Online) {
        this._updateNetworkStatus(ConnectionStatus.Offline);
      }
    });

    this.network.onConnect().subscribe(() => {
      if (this.status.getValue() === ConnectionStatus.Offline) {
        this._updateNetworkStatus(ConnectionStatus.Online);
      }
    });

    let status =
      this.network.type !== 'none'
        ? ConnectionStatus.Online
        : ConnectionStatus.Offline;

    this.status.next(status);
  }

  private async _updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    this._displayMessage(status);
  }

  private _displayMessage(status: ConnectionStatus) {
    const toastId = '__connectionNotify__';
    let toast = document.getElementById(toastId);
    if (!toast) {
      toast = document.createElement('div');
      toast.id = toastId;
      toast.style.position = 'absolute';
      toast.style.top = '0';
      toast.style.left = '50%';
      toast.style.transform = 'translate(-50%, 0)';
      toast.style.color = '#fff';
      toast.style.borderBottomRightRadius = '6px';
      toast.style.borderBottomLeftRadius = '6px';
      toast.style.paddingLeft = '10px';
      toast.style.paddingRight = '10px';

      document.body.appendChild(toast);
    }

    if (status == ConnectionStatus.Online) {
      toast.innerText = 'Підключення відновлено';
      toast.style.backgroundColor = '#2dd36f';

      setTimeout(() => {
        if (toast && toast.parentElement == document.body) {
          document.body.removeChild(toast);
        }
      }, 5000);
    } else {
      toast.style.backgroundColor = '#eb445a';
      toast.innerText = 'Відсутнє підключення до мережі інтернет';
    }
  }

  onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  async goOffline() {
    if (this.status.value != ConnectionStatus.Offline) {
      this._updateNetworkStatus(ConnectionStatus.Offline);
    }
  }

  async goOnline() {
    if (this.status.value != ConnectionStatus.Online) {
      this._updateNetworkStatus(ConnectionStatus.Online);
    }
  }
}
