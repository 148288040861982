<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" color="dark">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col size="12" size-md="6">
        <div style="position: relative">
          <span>Не друкується на чеку</span>
          <ion-textarea
            [(ngModel)]="note"
            class="custom-textarea"
            placeholder="Коментар на бігунок"
          ></ion-textarea>
          <ion-button
            class="delete-btn"
            *ngIf="note"
            size="small"
            fill="outline"
            color="medium"
            (click)="clearNote()"
          >
            Видалити
          </ion-button>
        </div>
      </ion-col>
      <ion-col size="12" size-md="6">
        <div style="position: relative">
          <span>Друкується на чеку</span>
          <ion-textarea
            [(ngModel)]="publicNote"
            class="custom-textarea"
            placeholder="Коментар на чек"
          ></ion-textarea>
          <ion-button
            class="delete-btn"
            *ngIf="publicNote"
            size="small"
            fill="outline"
            color="medium"
            (click)="clearPublicNote()"
          >
            Видалити
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-text-end">
  <ion-button color="success" fill="solid" (click)="save()">
    Зберегти
  </ion-button>
</ion-footer>

<style>
  ::ng-deep .note-modal {
    --width: 90%;
    --height: 270px;
    align-items: baseline;
    padding-top: 10px;
  }

  ion-textarea.custom-textarea {
    --background: #fff;
    --color: #373737;
    --padding-end: 100px;
    --padding-start: 10px;
    --placeholder-color: #ddd;
    --placeholder-opacity: 0.8;
    border-radius: 10px;
    font-size: 22px;
  }

  .delete-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 100;
  }

  @media (max-device-width: 600px) {
    ::ng-deep .note-modal {
      --height: 400px;
    }
  }
</style>
