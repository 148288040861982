import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { differenceInHours } from 'date-fns';
import {
  forkJoin,
  from,
  interval,
  Observable,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { StorageService } from 'src/app/services/storage.service';
import DateUtils from 'src/app/utility/date-utils';
import {
  EmployeeItem,
  EmployeeRepository,
} from '../repositories/employee.repository';
export interface IEmployeeSessionContract {
  id: number;
  employeeId: string;
  employeeName?: string;
  end?: string;
  start: string;
  storeId: number;
  updated?: string;
  uuid: string;
}

export interface IEmployeeSessionUpdate {
  uuid: string;
  storeId: number;
  employeeId: string;
  employeeName: string;
  sessionId: string;
  state: 'SessionStart' | 'SessionEnd' | 'SessionOpen' | string;
  date: string;
}

export interface IEmployeeSession {
  id: number;
  uuid: string;
  employee_id: string;
  employeeid?: string;
  employee_full_name?: string;
  start: Date;
  end: Date;
  changed?: boolean;
  store_id: number;
  close_reason: string;
}

class EmployeeSession implements IEmployeeSession {
  id: number;
  uuid: string;
  employee_id: string;
  employeeid?: string;
  start: Date;
  end: Date;
  changed?: boolean;
  store_id: number;
  close_reason: string;
  pausedAt?: Date;

  constructor(employeeId: string, storeId: number) {
    this.id = 0;
    this.store_id = storeId;
    this.uuid = UUID.UUID();
    this.employee_id = employeeId;
    this.start = new Date();
    this.end = null;
    this.changed = true;
  }
}

const EMPLOYEE_SESSIONS_KEY = '__employees_sessions_';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  private get terminalBaseUrl(): string {
    return `${this._settingsService.api_endpoint}/pos/terminal`;
  }

  constructor(
    private _storage: StorageService,
    private _employeeRepo: EmployeeRepository,
    private _http: HttpClient,
    private _settingsService: SettingsService
  ) {}

  private _getLastSession(employeeSessions: IEmployeeSession[]) {
    if (!employeeSessions || employeeSessions.length == 0) {
      console.log('Sessions not found.');
      return null;
    }

    // order sessions by start descending
    employeeSessions.sort(function (a, b) {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    });

    if (employeeSessions.length == 0) {
      console.log('Employee  sessions count - 0');
      return null;
    }

    const lastSession = employeeSessions[0];
    if (lastSession) {
      employeeSessions.forEach((s, i) => {
        if (i > 0) {
          s.end = lastSession.start;
          s.changed = true;
        }
      });
    }

    this._updateSessions(employeeSessions);
    return lastSession;
  }

  private _getLastOpenSession(employeeSessions: IEmployeeSession[]) {
    if (!employeeSessions || employeeSessions.length == 0) {
      console.log('Sessions not found.');
      return null;
    }

    // order sessions by start descending
    employeeSessions.sort(function (a, b) {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    });

    const employeeOpenSessions = employeeSessions.filter(
      (s) => !s.end || s.end === undefined || s.end === null
    );

    console.log(
      'Employee open sessions count - ' + employeeOpenSessions.length
    );

    if (employeeOpenSessions.length == 0) {
      return null;
    }

    let openSession: IEmployeeSession;
    if (employeeOpenSessions.length === 1) {
      console.log(
        'Found open session. SessionId ' + employeeOpenSessions[0].uuid
      );
      openSession = employeeOpenSessions[0];
    } else {
      employeeOpenSessions.sort(function (a, b) {
        return new Date(b.start).getTime() - new Date(a.start).getTime();
      });

      const lastOpenSession = employeeOpenSessions[0];
      console.log('Found open session. SessionId ' + lastOpenSession.uuid);

      // close all opened session except last one
      employeeOpenSessions.forEach((s, i) => {
        if (i > 0) {
          s.end = lastOpenSession.start;
          s.changed = true;
        }
      });

      openSession = lastOpenSession;
    }

    return openSession;
  }

  getLastOpenSession(employeeId: string): Observable<IEmployeeSession> {
    console.log('Get employee last open session. EmployeeId: ' + employeeId);

    return forkJoin([this._getEmloyeeSessions(employeeId)]).pipe(
      mergeMap((result) => {
        const employeeSessions = result[0];
        const openSession = this._getLastOpenSession(employeeSessions);
        return of(openSession);
      })
    );
  }

  getLastSession(employeeId: string): Promise<IEmployeeSession> {
    console.log('Get employee last session. EmployeeId: ' + employeeId);

    return this._getEmloyeeSessions(employeeId).then((result) => {
      const openSession = this._getLastSession(result);
      return openSession;
    });
  }

  async getActiveSession(employeeId: string): Promise<IEmployeeSession> {
    console.log('Get employee active session. EmployeeId: ' + employeeId);
    const employeeSessions = await this._getEmloyeeSessions(employeeId);
    const lastActivityDate = await this._getEmloyeeLastActivity(employeeId);

    const lastActivityAgoHours = this._getDurationHours(lastActivityDate);
    const expirationHours = 4;

    console.log('lastActivityAgoHours ' + lastActivityAgoHours);

    if (!employeeSessions || employeeSessions.length == 0) {
      console.log('Sessions not found.');
      return null;
    }

    let openSession = this._getLastOpenSession(employeeSessions);

    const employeeOpenSessions = employeeSessions.filter(
      (s) => !s.end || s.end === undefined || s.end === null
    );

    if (openSession) {
      if (lastActivityAgoHours == -1) {
        const openHoursAgo = this._getDurationHours(openSession.start);
        if (openHoursAgo > 15) {
          // end session
          openSession.end = new Date();
          openSession.changed = true;

          return this._updateSessions(employeeOpenSessions).then(() =>
            this._updateSession(openSession)
          );
        } else {
          return this._updateSessions(employeeOpenSessions).then(
            () => openSession
          );
        }
      } else {
        if (lastActivityAgoHours > expirationHours) {
          // end session
          openSession.end = lastActivityDate;
          openSession.changed = true;

          return this._updateSessions(employeeOpenSessions).then(() =>
            this._updateSession(openSession)
          );
        } else {
          // return active session
          console.log('Found active session. SessionId ' + openSession.uuid);
          return this._updateSessions(employeeOpenSessions).then(
            () => openSession
          );
        }
      }
    }
  }

  private _getDurationHours(fromDate: Date): number {
    if (!fromDate) {
      return -1;
    }

    const duration = differenceInHours(new Date(), fromDate);
    return Math.round(duration);
  }

  private async _getEmployeeSessions(
    employeeId: string
  ): Promise<IEmployeeSession[]> {
    console.log('Get employee active session. EmployeeId: ' + employeeId);
    return this._getSessions().then((sessions: IEmployeeSession[]) => {
      if (!sessions || sessions.length == 0) {
        return [];
      }

      if (employeeId) {
        return sessions.filter((s) => s.employee_id === employeeId);
      } else {
        return sessions;
      }
    });
  }

  getEmployeeSessions(employeeId: string) {
    return this._getEmployeeSessions(employeeId);
  }

  getEmployeeByCode(code: string): Promise<EmployeeItem> {
    return this._employeeRepo.getEmployees().then((employees) => {
      return employees.find((e) => e.terminalcode === code);
    });
  }

  private _startEmployeeSession(
    employeeId: string,
    storeId: number
  ): Promise<IEmployeeSession> {
    console.log('Start employee session');

    if (!employeeId) {
      console.error('EmployeeId not provided');
      return null;
    }

    return this._createNewEmployeeSession(employeeId, storeId);
  }

  syncEmployeeSessions = (): Promise<void> => this._syncEmployeeSessions();

  private _syncInterval = interval(10000);
  private _syncSub: Subscription;

  private _syncEmployeeSessions(): Promise<void> {
    console.log('Sync employee sessions');
    return this._getChangedEmloyeeSessions().then((changedSessions) => {
      if (!changedSessions || changedSessions.length == 0) {
        return null;
      }

      changedSessions.forEach((s: any) => {
        if (s.end) {
          s.end = DateUtils.format(s.end);
        }

        if (s.start) {
          s.start = DateUtils.format(s.start);
        }
      });
      return this._http
        .put(`${this.terminalBaseUrl}/sync-employee-sessions`, changedSessions)
        .pipe(
          catchError((err) => {
            if (this._syncSub) {
              this._syncSub.unsubscribe();
              this._syncSub = null;
            }

            this._syncSub = this._syncInterval.subscribe(() => {
              this._syncEmployeeSessions();
            });

            return throwError(() => new Error(err));
          }),
          mergeMap((result) =>
            this._getSessions().then((storedSessions) => {
              const uuids = Object.keys(result);
              console.log('Updated sessions:');
              console.log(uuids);

              const sessionsToUpdate: IEmployeeSession[] = [];
              uuids.forEach((uuid) => {
                const session = storedSessions.find((s) => s.uuid == uuid);
                if (session) {
                  session.id = result[uuid];
                  session.changed = false;
                  sessionsToUpdate.push(session);
                }
              });

              if (this._syncSub && this._syncSub) {
                this._syncSub.unsubscribe();
                this._syncSub = null;
              }

              return this._updateSessions(sessionsToUpdate);
            })
          )
        )
        .toPromise();
    });
  }

  get isDataSynced(): Promise<boolean> {
    return this._getChangedEmloyeeSessions().then((res) => res.length == 0);
  }

  private _getChangedEmloyeeSessions(): Promise<EmployeeSession[]> {
    return this._getSessions().then((res) => res.filter((s) => s.changed));
  }

  startSession(employeeId: string, storeId: number): Promise<IEmployeeSession> {
    return this._startEmployeeSession(employeeId, storeId);
  }

  private _closeSession(sessionUuid: string): Promise<IEmployeeSession> {
    if (!sessionUuid) {
      return null;
    }

    return this._getEmloyeeSession(sessionUuid).then((session) => {
      if (!session) {
        return null;
      }

      session.end = new Date();
      session.changed = true;
      session.close_reason = 'MANUAL_CLOSE';

      return this._updateSession(session);
    });
  }

  async closeAllOpenSessions(
    reason: string = 'CASHBOX_SESSION_CLOSE'
  ): Promise<void> {
    await this._getSessions().then((sessions) => {
      if (sessions && sessions.length > 0) {
        sessions.forEach((s) => {
          if (!s.end) {
            s.end = new Date();
            s.changed = true;
            s.close_reason = reason;
          }
        });

        return this._updateSessions(sessions);
      }
    });
  }

  closeSession(sessionUuid: string): Promise<Object> {
    return this._closeSession(sessionUuid);
  }

  async loadSessions(employeeSessions: IEmployeeSession[]): Promise<void> {
    return this._getSessions().then((res) => {
      const sessions = res || [];
      employeeSessions.forEach((es) => {
        const storedSession = res.find(
          (ss) => ss.id == es.id || (ss.uuid == es.uuid && ss.uuid)
        );

        if (storedSession != null) {
          return;
        } else {
          if (!es.uuid) {
            es.uuid = UUID.UUID();
          }
          sessions.push(es);
        }
      });

      return this._updateSessions(sessions);
    });
  }

  updateEmloyeeSession(session: IEmployeeSession): Promise<IEmployeeSession> {
    return this._saveSession(session);
  }

  updateEmloyeeSessions(
    employeeId: string,
    sessions: IEmployeeSession[]
  ): Promise<void> {
    // order sessions by start descending
    sessions.sort(function (a, b) {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    });

    const sessionsToStore: any[] = [];
    sessions.forEach((s, i) => {
      if (i < 10) {
        sessionsToStore.push(s);
      }
    });

    return this._removeEmployeeSessions(employeeId).then(() =>
      this._saveSessions(sessionsToStore)
    );
  }

  private _removeEmployeeSessions(employeeId: string): Promise<void> {
    return this._getSessions().then((sessions) => {
      const sessionsToStore: any[] = [];
      sessions.forEach((s, i) => {
        if (s.employeeid != employeeId) {
          sessionsToStore.push(s);
        }
      });

      return this._saveSessions(sessionsToStore);
    });
  }

  updateEmloyeeActivity(employeeId: string, date: Date): Promise<void> {
    return this._employeeRepo.getEmployee(employeeId).then((employee) => {
      if (!employee) {
        return null;
      }

      employee.lastActivity = date;
      return this._employeeRepo.saveEmployee(employee);
    });
  }

  removeSessions() {
    return this._removeSessions();
  }

  private async _createNewEmployeeSession(
    employeeId: string,
    storeId: number
  ): Promise<IEmployeeSession> {
    const newSession = this._getNewSession(employeeId, storeId);

    console.log('Sessions started');
    console.log('SessionId: ' + newSession.uuid);
    console.log('EmployeeId: ' + newSession.employee_id);

    return this._saveSession(newSession);
  }

  private _getEmloyeeLastActivity(employeeId: string): Promise<Date> {
    return this._employeeRepo
      .getEmployee(employeeId)
      .then((employee) => employee.lastActivity);
  }

  private _getEmloyeeSessions(employeeId: string): Promise<IEmployeeSession[]> {
    return this._getSessions().then((sessions) =>
      sessions.filter(
        (s) =>
          employeeId &&
          (s.employee_id === employeeId || s.employeeid === employeeId)
      )
    );
  }

  private _getEmloyeeSession(uuid: string): Promise<IEmployeeSession> {
    return this._getSessions().then((sessions) =>
      sessions.find((s) => s.uuid === uuid)
    );
  }

  private async _updateSession(
    sessionToUpdate: IEmployeeSession
  ): Promise<IEmployeeSession> {
    return this._updateSessions([sessionToUpdate]).then(() => sessionToUpdate);
  }

  private _updateSessions(newSessions: IEmployeeSession[]): Promise<void> {
    return this._getSessions().then((sessions: IEmployeeSession[]) => {
      if (newSessions == null || newSessions.length == 0) {
        return of(null);
      }

      const sessionsToStore: IEmployeeSession[] = sessions;
      newSessions.forEach((newSession) => {
        const storedSession = sessions.find((s, index) => {
          if (s.uuid == newSession.uuid) {
            sessionsToStore.splice(index, 1);
            sessionsToStore.splice(index, 0, newSession);
            return true;
          }
        });

        if (!storedSession) {
          sessionsToStore.push(newSession);
        }
      });

      return this._saveSessions(sessionsToStore);
    });
  }

  private async _saveSession(
    session: EmployeeSession
  ): Promise<EmployeeSession> {
    if (!session) {
      return null;
    }

    return this._getSessions().then((sessions) => {
      if (!sessions) {
        sessions = [session];
      } else {
        const storedSession = sessions.find((s) => s.uuid == session.uuid);
        if (storedSession) {
          return this._updateSession(storedSession);
        }

        sessions.push(session);
      }

      return this._saveSessions(sessions).then(() => {
        console.log('Sessions stored');
        return session;
      });
    });
  }

  private _getSessions(): Promise<IEmployeeSession[]> {
    return this._storage.get(EMPLOYEE_SESSIONS_KEY).then((res) => res || []);
  }

  private _saveSessions(sessions: EmployeeSession[]): Promise<any> {
    return this._storage.set(EMPLOYEE_SESSIONS_KEY, sessions).then(() => {
      return this._syncEmployeeSessions().catch((err) => {
        console.error('Employee sessions sync error');
        return of(null);
      });
    });
  }

  private _removeSessions(): Observable<any> {
    return from(this._storage.remove(EMPLOYEE_SESSIONS_KEY));
  }

  private _getNewSession(
    employeeId: string,
    storeId: number
  ): IEmployeeSession {
    return new EmployeeSession(employeeId, storeId);
  }
}
