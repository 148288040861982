import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './note-dialog.component.html',
})
export class NoteDialogComponent {
  note: string;
  publicNote: string;
  title = 'Коментар';

  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }

  save() {
    this.modalController.dismiss({
      note: this.note,
      publicNote: this.publicNote,
    });
  }

  clearPublicNote() {
    this.publicNote = null;
  }

  clearNote() {
    this.note = null;
  }
}
