import { EventEmitter, Injectable } from '@angular/core';
import { CompanyStorage } from 'src/app/_core/company-storage';

class HallContract {
  id: string;
  name: string;
  tables: ITableContract[];
}

export interface ITableContract {
  id: string;
  name: string;
  number: number;
  hallId: string;
}

const HALLS_TABLE_KEY = '__halls';

@Injectable({ providedIn: 'root' })
export class HallsRepository {
  onUpdated = new EventEmitter<HallContract[]>();
  constructor(private _companyStorage: CompanyStorage) {}

  async getHalls(): Promise<HallContract[]> {
    const repo = await this._companyStorage.repository<HallContract>(
      HALLS_TABLE_KEY
    );

    return repo.getItems();
  }

  async loadHalls(halls: HallContract[]) {
    const repo = await this._companyStorage.repository<HallContract>(
      HALLS_TABLE_KEY
    );

    repo.loadItems(halls);
    this.onUpdated.emit(halls);
  }

  async clearStorage() {
    const repo = await this._companyStorage.repository<HallContract>(
      HALLS_TABLE_KEY
    );

    repo.deleteTable();
  }
}
