import { Injectable } from '@angular/core';
import { CompanyStorage } from 'src/app/_core/company-storage';

export class PromoContract {
  id: string;
  entityId: string | number;
  entity: any;
  date: Date;
  employeeId?: string;
}

const PROMOS_TABLE_KEY = '__promos';

@Injectable({ providedIn: 'root' })
export class PromosRepository {
  constructor(private _companyStorage: CompanyStorage) {}

  async getPromos(): Promise<PromoContract[]> {
    const repo = await this._companyStorage.repository<PromoContract>(
      PROMOS_TABLE_KEY
    );

    return repo.getItems();
  }

  async loadPromos(customers: PromoContract[]) {
    const repo = await this._companyStorage.repository<PromoContract>(
      PROMOS_TABLE_KEY
    );

    repo.loadItems(customers);
  }

  async clearStorage() {
    const repo = await this._companyStorage.repository<PromoContract>(
      PROMOS_TABLE_KEY
    );

    repo.deleteTable();
  }
}
