import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { GenericRepository, IGenericItem } from './generic.repository';
import { CompanyStorage } from 'src/app/_core/company-storage';

export interface IPrinterItem {
  uuid?: string;
  name?: string;
  address?: string;
  ip?: string;
  port?: number;
  class?: string;
  type?: string;
  printCheck?: boolean;
  work_areas?: any[];
  workAreasJson?: string;
  index?: number;
  printLogo?: boolean;
  graphicPrint?: boolean;
  graphicPrintImageWidth?: number;
  graphicPrintHtmlWidth?: string;
  graphicPrinFontSize?: number;
  printWidth?: string;
  printWidthSymbols?: number;
  codePage?: string;
  codePageNumber?: number;
  codePageStrange?: string;
  remoteDebug?: boolean;
  bottomRows?: string;
  qrCodeUseImg?: boolean;
  qrCodeModel?: string;
  qrCodeSize?: string;
  qrCodeErrorLevel?: string;
  logoWidth?: number;
  logoHeight?: number;
  logoAlgorithm?: 'bayer' | 'threshold' | 'floydsteinberg' | 'atkinson';
  logoThreshold?: number;
  textSize?: 'normal' | 'small';
  printWorkAreasOnPayment?: boolean;
}

class PrinterItem implements IGenericItem, IPrinterItem {
  uuid?: string;
  terminalId?: string;
  companyId?: string;
  name?: string;
  address?: string;
  ip?: string;
  port?: number;
  class?: string;
  type?: string;
  printCheck?: boolean;
  work_areas?: any[];
  index?: number;
  printLogo?: boolean;
  graphicPrint?: boolean;
  graphicPrintImageWidth?: number;
  graphicPrintHtmlWidth?: string;
  graphicPrinFontSize?: number;
  printWidth?: string;
  codePage?: string = 'cp866';
  codePageStrange?: string;
  bottomRows?: string;
  qrCodeUseImg?: boolean;
  qrCodeModel?: string;
  qrCodeSize?: string;
  qrCodeErrorLevel?: string;
  logoWidth?: number;
  logoHeight?: number;
  logoAlgorithm?: 'bayer' | 'threshold' | 'floydsteinberg' | 'atkinson';
  logoThreshold?: number;
  textSize?: 'normal' | 'small';
  printWorkAreasOnPayment?: boolean;

  constructor(
    name: string,
    ipAddress: string,
    type: string,
    port: number,
    codePage?: string,
    codePageStrange?: string
  ) {
    this.name = name || 'Printer';
    this.uuid = UUID.UUID();
    this.ip = ipAddress;
    this.type = type;
    this.port = port;
    this.address = ipAddress;
    this.codePage = codePage;
    this.codePageStrange = codePageStrange;
    this.graphicPrintImageWidth = 400;
    this.graphicPrintHtmlWidth = '500px';
    this.graphicPrinFontSize = 20;
    this.bottomRows = '4';
    this.qrCodeUseImg = true;
    this.qrCodeModel = '1';
    this.qrCodeSize = '6';
    this.qrCodeErrorLevel = 'm';
    this.logoWidth = 200;
    this.logoHeight = 200;
    this.logoAlgorithm = 'atkinson';
    this.logoThreshold = 128;
    this.textSize = 'normal';
    this.printWorkAreasOnPayment = true;
  }
}

const TABLE_KEY = '__printers';
const POS_TERMINAL_DEVICES = 'POS_TERMINAL_DEVICES';

@Injectable({ providedIn: 'root' })
export class PrinterRepository {
  constructor(
    private _repository: GenericRepository,
    private _storage: CompanyStorage
  ) {}
  
  savePrinter(printer: PrinterItem): Promise<void> {
    return this._repository.addItem(TABLE_KEY, printer);
  }
  createPrinter(
    name: string,
    ipAddress: string,
    port: number,
    type: string,
    codePage?: string,
    codePage2?: string
  ): IPrinterItem {
    return new PrinterItem(name, ipAddress, type, port, codePage, codePage2);
  }

  async getPrinters(): Promise<PrinterItem[]> {
    await this._checkLegacyPrinters();
    return this._repository.getItems(TABLE_KEY);
  }
  updatePrinter(printer: PrinterItem): Promise<void> {
    return this._repository.updateItem(TABLE_KEY, printer);
  }
  deletePrinter(printer: PrinterItem): Promise<void> {
    return this._repository.deleteItem(TABLE_KEY, printer.uuid);
  }

  async savePrinters(printers: PrinterItem[]): Promise<void> {
    await this._storage.set(TABLE_KEY, printers);
    return this._repository.loadItems(TABLE_KEY, printers);
  }

  private async _checkLegacyPrinters(): Promise<void> {
    const legacyPrinters = await this._storage.get(POS_TERMINAL_DEVICES);
    if (!legacyPrinters || legacyPrinters.length == 0) {
      return null;
    } else {
      const printersToAdd = legacyPrinters.map((lp) => {
        const p = this.createPrinter(lp.name, lp.ip, lp.port, lp.type);
        p.address = lp.address;
        p.class = lp.class;
        p.printCheck = lp.selected;

        return p;
      });

      await this.savePrinters(printersToAdd);
      await this._storage.remove(POS_TERMINAL_DEVICES);
    }
  }
}
