import { Platform } from '@ionic/angular';
import { EventEmitter, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  from,
  of,
  forkJoin,
  throwError,
} from 'rxjs';
import { take, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';

// const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const CONNECTION_TOKEN = 'CONNECTION_TOKEN';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private get baseUrl() {
    return this._settingsService.api_endpoint;
  }
  private readonly userInfoKey = 'userInfo';
  onUserInfo: EventEmitter<any> = new EventEmitter<any>();

  public ticket: Observable<any>;
  public isLoggedIn: Observable<boolean>;
  public hasConnectionToken: Observable<boolean>;

  private ticketData = new BehaviorSubject(null);
  public user: Observable<any>;
  // private userData = new BehaviorSubject(null);
  private _tenant: any;
  get tenantId(): string {
    return this._tenant?.store?.company_id;
  }

  constructor(
    private storage: StorageService,
    private _settingsService: SettingsService,
    private http: HttpClient,
    private plt: Platform
  ) {
    this.loadStoredToken();
  }

  loadStoredToken() {
    const platformObs = from(this.plt.ready());

    this.ticket = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(TOKEN_KEY));
      }),
      map((token) => {
        if (token) {
          // let decoded = helper.decodeToken(token);
          this.ticketData.next(token);
          return true;
        } else {
          return null;
        }
      })
    );

    this.isLoggedIn = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(TOKEN_KEY));
      }),
      map((token) => {
        if (token) {
          return true;
        } else {
          return false;
        }
      })
    );

    this.hasConnectionToken = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(CONNECTION_TOKEN));
      }),
      map((token) => {
        if (token) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  forgotPassword(phoneNumber: string): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/profile/reset-password-by-phone`,
      { phoneNumber }
    );
  }

  login(credentials: { email: string; pw: string }): Observable<any> {
    return this._getToken(credentials.email, credentials.pw).pipe(
      take(1),
      mergeMap((ticket: any) => this._storeTokens(ticket))
    );
  }

  getToken(email: string, pw: string): Observable<any> {
    return this._getToken(email, pw);
  }

  private _getToken(email: string, pw: string): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/token`,
      `grant_type=password&username=${email}&password=${pw}&deviceType=mobile`
    );
  }

  private _refreshToken(refreshToken: string) {
    return this.http.post(
      `${this.baseUrl}/token`,
      `grant_type=refresh_token&refresh_token=${refreshToken}`
    );
  }

  refreshToken(): Observable<any> {
    console.log('Start refresh token process');
    return this._getStoredRefreshToken().pipe(
      mergeMap((refreshToken) => {
        console.log(
          refreshToken ? 'Refresh token found' : 'Refresh token NOT found'
        );
        if (!refreshToken) {
          return throwError({
            status: 404,
            message: 'Refresh token NOT found',
          });
        }

        return this._refreshToken(refreshToken).pipe(
          mergeMap((ticket: any) => {
            console.log('Success. Access token refreshed.');
            return this._storeTokens(ticket);
          })
        );
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(`ERROR. Refresh token error. Error code ${error.status}.`);
        if (error.status === 400) {
          console.log('Clear access tokens');
          return this._clearAccessTokens();
        } else {
          return throwError(error);
        }
      })
    );
  }

  accessToken(): Observable<string> {
    const ticketSub =
      this.ticketData.value != null
        ? of(this.ticketData.value)
        : from(this.storage.get(TOKEN_KEY));

    return ticketSub.pipe(
      map((ticket) => {
        if (ticket) {
          return ticket.access_token;
        } else {
          return null;
        }
      })
    );
  }

  private _storeTokens(ticket: any) {
    console.log('Store access token');
    if (!ticket) {
      console.error('User ticket not provided');
      return throwError({
        error: 'Token store error',
        description: 'User ticket not provided',
      });
    } else {
      console.log('Access token stored');
      this.ticketData.next(ticket);
      return forkJoin([
        from(this.storage.set(TOKEN_KEY, ticket)),
        from(this.storage.set(ACCESS_TOKEN, ticket.access_token)),
        from(this.storage.set(REFRESH_TOKEN, ticket.refresh_token)),
      ]);
    }
  }

  private _clearAccessTokens() {
    return forkJoin([
      from(this.storage.remove(TOKEN_KEY)),
      from(this.storage.remove(ACCESS_TOKEN)),
      from(this.storage.remove(REFRESH_TOKEN)),
    ]);
  }

  getTicket() {
    return this.ticketData.getValue();
  }

  private _getStoredRefreshToken(): Observable<string> {
    return from(this.storage.get(REFRESH_TOKEN));
  }

  get userInfo() {
    return localStorage.getItem(this.userInfoKey)
      ? JSON.parse(localStorage.getItem(this.userInfoKey))
      : null;
  }

  get userInfoSubscription(): Observable<any> {
    return new Observable((observer) => {
      if (this.userInfo) {
        observer.next(this.userInfo);
      } else {
        this.onUserInfo.subscribe((userInfo: any) => {
          observer.next(userInfo);
        });
      }
    });
  }

  logout() {
    return forkJoin([
      this._clearAccessTokens(),
      from(this.storage.remove(TOKEN_KEY)),
      // this.companyDeviceService.clearDeviceId(),
      from(this.storage.remove('CURRENT_DEVICE_ID')),
      from(this.storage.remove('NT_USER_SESSION_ID')),
      from(this.storage.remove('__NOTIFICATIONS')), // remove legacy data
    ]).pipe(map(() => this.ticketData.next(null)));
  }

  isAuthenticated() {
    return this.ticketData.value;
  }

  get isDemoLogin(): boolean {
    const ticketData = this.getTicket();
    return (
      ticketData &&
      ticketData.phone == '380681112233' &&
      ticketData.username == '380681112233'
    );
  }
}
