import { Inject, Injectable, isDevMode } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { TenantAuthService } from './tenant-auth.service';

const SKIP_ERROR_1 =
  'Uncaught TypeError: callback.success.apply is not a function';

@Injectable({ providedIn: 'root' })
export class MonitoringService {
  private _appInsights: ApplicationInsights;
  private _versionNumber: string;
  private taxLogger: TaxRegisterLogger;
  private _deviceUuid: string;
  private _devicePlatform: string;

  constructor(
    @Inject('ENVIRONMENT')
    private ENVIRONMENT: { appInsights: any; version: string },
    private appVersion: AppVersion,
    private _tenantAuth: TenantAuthService
  ) {}

  startMonitoring(deviceUuid: string, platform: string) {
    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.ENVIRONMENT.appInsights.instrumentationKey,
        // enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this._appInsights.loadAppInsights();

    this._deviceUuid = deviceUuid;
    this._devicePlatform = platform;

    // define a new console
    const that = this;
    const _isDevMode = isDevMode();
    var console = (function (oldCons) {
      return {
        log: function (text, props?: any) {
          oldCons.log(text);

          // if (!_isDevMode) {
          //   let _props = that._mergeProps(
          //     { source: 'console', level: 'log' },
          //     props
          //   );
          //   that.logTrace(text, _props);
          // }
        },
        info: function (text, props?: any) {
          oldCons.info(text);

          // if (!_isDevMode) {
          //   let _props = that._mergeProps(
          //     { source: 'console', level: 'info' },
          //     props
          //   );
          //   that.logTrace(text, _props);
          // }
        },
        warn: function (text, props?: any) {
          oldCons.error(text);

          if (!_isDevMode) {
            let _props = that._mergeProps(
              { source: 'console', level: 'warn' },
              props
            );
            that.logException(text, 1, _props);
          }
        },
        error: function (text, props?: any) {
          oldCons.error(text);

          // if (text == SKIP_ERROR_1) {
          //   return;
          // }

          if (!_isDevMode) {
            let _props = that._mergeProps(
              { source: 'console', level: 'error' },
              props
            );
            that.logException(text, 1, _props);
          }
        },
      };
    })(window.console);
    (<any>window).console = console;

    if (platform == 'web_browser') {
      this._versionNumber = this.ENVIRONMENT.version;
    } else {
      this.appVersion
        .getVersionNumber()
        .then((res) => {
          this._versionNumber = res;
        })
        .catch(() => this.ENVIRONMENT.version);
    }
  }

  private _mergeProps(propsLeft: any, propsRight?: any): Object {
    if (propsRight && typeof propsRight == 'object') {
      propsLeft = Object.assign({}, propsLeft, propsRight);
    }

    return propsLeft;
  }

  private _getProperties() {
    const _isDevMode = isDevMode();
    const _env = _isDevMode ? 'Development' : 'Production';
    const tenantId = this._tenantAuth.tenantId;

    return {
      companyId: tenantId,
      companyNumber: tenantId,
      deviceId: this._deviceUuid,
      // userId: this.terminalClient.loggedInEmployee?.userid,
      environment: _env,
      platform: this._devicePlatform,
      version: this._versionNumber,
    };
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this._appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this._appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this._appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(
    exception: Error,
    severityLevel?: number,
    properties?: { [key: string]: any }
  ) {
    const customProperties = this._getProperties();
    const props = Object.assign({}, customProperties, properties);
    this._appInsights.trackException(
      {
        exception: exception,
        severityLevel: severityLevel,
      },
      props
    );
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    const customProperties = this._getProperties();
    const props = Object.assign({}, customProperties, properties);
    this._appInsights.trackTrace({ message: message }, props);
  }

  getTaxLogger(): TaxRegisterLogger {
    if (this.taxLogger) {
      return this.taxLogger;
    }

    const customProperties = this._getProperties();
    return new TaxRegisterLogger(
      this.ENVIRONMENT.appInsights.taxRegisterKey,
      customProperties
    );
  }
}

export interface ITaxRegisterLogger {
  logException(
    exception: any,
    severityLevel?: number,
    properties?: { [key: string]: any }
  ): void;
  logTrace(message: string, properties?: { [key: string]: any }): void;
}

class TaxRegisterLogger implements ITaxRegisterLogger {
  private _appInsights: ApplicationInsights;
  private _systemProperties = this.customProperties;
  constructor(
    private instrumentationKey: string,
    private customProperties?: any
  ) {
    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.instrumentationKey,
      },
    });
    this._appInsights.loadAppInsights();
  }

  logException(
    exception: any,
    severityLevel?: number,
    properties?: { [key: string]: any }
  ) {
    const props = Object.assign({}, this._systemProperties, properties);
    this._appInsights.trackException(
      {
        exception: exception,
        severityLevel: severityLevel,
      },
      props
    );
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    const props = Object.assign({}, this._systemProperties, properties);
    this._appInsights.trackTrace({ message: message }, props);
  }
}
